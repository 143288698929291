import { updateObject } from '../../utils';
import { ActionTypes } from '../constants/action.types';
import { IStripeAction, IStripeState } from './types';

export const initialState: IStripeState = {
  loadingStripe: false,
};

export const stripeReducer = (state = initialState, action?: IStripeAction) => {
  switch (action?.type) {
    case ActionTypes.GET_STRIPE_START:
      return updateObject(state, { loadingStripe: true });
    case ActionTypes.GET_STRIPE_SUCCESS:
      return updateObject(state, { loadingStripe: false });
    case ActionTypes.GET_STRIPE_FAIL:
      return updateObject(state, { loadingStripe: false, error: action.error });
    default:
      return state;
  }
};
