interface PlusIconProps {
  size: number;
  fill: string;
}

const PlusIcon = ({ size, fill }: PlusIconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill}>
      <path
        d="M9.98828 0.741602C9.35315 0.741602 8.83828 1.25647 8.83828 1.8916V18.1085C8.83828 18.7436 9.35315 19.2585 9.98828 19.2585H10.0124C10.6475 19.2585 11.1624 18.7436 11.1624 18.1085V1.8916C11.1624 1.25647 10.6475 0.741602 10.0124 0.741602H9.98828Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinecap="round"
      />
      <path
        d="M1.8916 8.83792C1.25647 8.83792 0.741602 9.35279 0.741602 9.98791V10.012C0.741602 10.6471 1.25647 11.162 1.8916 11.162H18.1085C18.7436 11.162 19.2585 10.6471 19.2585 10.012V9.98791C19.2585 9.35279 18.7436 8.83792 18.1085 8.83792H1.8916Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PlusIcon;
