export interface IFreshdeskEmail {
  name: string;
  email: string;
  message?: string;
}

export enum FreshdeskEmailType {
  SUPPORT = 'support',
  ORDER_HQ = 'orderHq',
  ORDER_VQ = 'orderVq',
}
