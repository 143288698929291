import { snipcartAxios } from '../config/snipcart-axios.config';
import { ISnipcartProduct } from '../utils/constants/snipcart';

const url = 'https://app.snipcart.com/api/';

export class SnipcartService {
  async getProduct(id: string): Promise<ISnipcartProduct> {
    const productsUrl = `${url}/products/${id}`;
    const { data } = await snipcartAxios.get<ISnipcartProduct>(productsUrl);
    return data;
  }
}
