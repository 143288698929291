import ImageSlider from './ImageSlider';
import classes from './HeroCarousel.module.css';

import slide1 from '../../assets/img/HeroCarousel/With-Quotient.jpg';
import slide2 from '../../assets/img/HeroCarousel/Share-your-files.jpg';
import slide3 from '../../assets/img/HeroCarousel/You-can-create.jpg';
import slide4 from '../../assets/img/HeroCarousel/You-can-message.jpg';
import slide5 from '../../assets/img/HeroCarousel/You-can-share.jpg';

const HeroCarousel = () => {
  return (
    <div className={classes.slideContainer} data-cy="heroCarousel">
      <ImageSlider
        slide1={slide1}
        slide2={slide2}
        slide3={slide3}
        slide4={slide4}
        slide5={slide5}
      />
    </div>
  );
};

export default HeroCarousel;
