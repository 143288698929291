/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';

const freshdeskAxios = axios.create({});

export const freshdeskConfig = async (config: AxiosRequestConfig) => {
  const basicAuth = `${process.env.REACT_APP_FRESHDESK_KEY}`;

  if (!config.headers) {
    config.headers = {};
  }

  config.headers.Authorization = `Basic ${basicAuth}`;
  config.headers['Content-Type'] = 'application/json';

  return config;
};

freshdeskAxios.interceptors.request.use(freshdeskConfig);

export { freshdeskAxios };
