interface InvitationProps {
  width: string;
  height: string;
  fill: string;
}

const Invitation = ({ width, height, fill }: InvitationProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.66045e-09C15.7652 -4.26217e-05 16.5015 0.292325 17.0583 0.817284C17.615 1.34224 17.9501 2.06011 17.995 2.824L18 3V7.35L18.594 7.086C19.208 6.813 19.916 7.236 19.994 7.884L20 8V16C20.0002 16.5046 19.8096 16.9906 19.4665 17.3605C19.1234 17.7305 18.6532 17.9572 18.15 17.995L18 18H2C1.49542 18.0002 1.00943 17.8096 0.639452 17.4665C0.269471 17.1234 0.0428433 16.6532 0.00500011 16.15L0 16V8C0 7.328 0.675 6.853 1.297 7.045L1.407 7.086L2 7.35V3C1.99996 2.23479 2.29233 1.49849 2.81728 0.941739C3.34224 0.384993 4.06011 0.0498925 4.824 0.00500012L5 4.66045e-09H15ZM18 9.539L10.812 12.733C10.5564 12.8466 10.2797 12.9053 10 12.9053C9.72027 12.9053 9.44364 12.8466 9.188 12.733L2 9.54V16H18V9.539ZM15 2H5C4.73478 2 4.48043 2.10536 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3V8.239L10 10.906L16 8.239V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2ZM10 5C10.2549 5.00028 10.5 5.09788 10.6854 5.27285C10.8707 5.44782 10.9822 5.68695 10.9972 5.94139C11.0121 6.19584 10.9293 6.44638 10.7657 6.64183C10.6021 6.83729 10.3701 6.9629 10.117 6.993L10 7H8C7.74512 6.99972 7.49997 6.90212 7.31463 6.72715C7.1293 6.55218 7.01777 6.31305 7.00283 6.05861C6.98789 5.80416 7.07067 5.55362 7.23427 5.35817C7.39786 5.16271 7.62991 5.0371 7.883 5.007L8 5H10Z"
        fill={fill}
      />
    </svg>
  );
};

export default Invitation;
