import classes from './Button.module.css';

interface ButtonProbs {
  value: string;
}

const Button = ({ value }: ButtonProbs) => {
  return (
    <button type="button" className={classes.button}>
      {value}
    </button>
  );
};

export default Button;
