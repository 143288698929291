import { FC } from 'react';
import Accordion from '../../components/Accordion/Accordion';
import Check from '../../assets/svg/Check';
import classes from './Home.module.css';
import { changeData, dutyData } from '../../utils/constants';
import FatherAndDaughter from '../../assets/FatherAndDaughter.png';
import GradientDots from '../../assets/svg/GradientDots';
import VerticalCarousel from '../../components/VerticalCarousel/VerticalCarousel';
import Footer from '../../components/Footer/Footer';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel';
import CTABanner from '../../components/CTABanner/CTABanner';
import GradientDotsMobile from '../../assets/svg/GradientDotsMobile';

const Home: FC = () => (
  <div className={classes.container}>
    <HeroCarousel />
    <div className={classes.dots}>
      <GradientDots />
    </div>
    <div className={classes.dotsMobile}>
      <GradientDotsMobile />
    </div>
    <div>
      <div>
        <h1 className={classes.title}>
          Our duty is to keep you and your loved ones safe
        </h1>
      </div>
      <div className={classes.containerSection}>
        <div className={classes.dutyContainer}>
          <div className={classes.leftCol}>
            <Accordion data={dutyData} />
          </div>
          <div className={classes.rightCol}>
            <img src={FatherAndDaughter} alt="Father and daughter" />
            <div className={classes.youAreSafeBox}>
              <div className={classes.youAreSafeCheck}>
                <Check fill="#108B54" />
              </div>
              <div className={classes.youAreSafeText}>You are safe</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={classes.containerSection}>
      <div>
        <h1 className={classes.title}>
          The wait is finally over; change the way to keep in touch
        </h1>
      </div>
      <div className={classes.subContainerSection}>
        <VerticalCarousel data={changeData} />
      </div>
    </div>
    <CTABanner />
    <Footer />
  </div>
);

export default Home;
