import { NavLink } from 'react-router-dom';
import classes from './HeroCarousel.module.css';

interface SliderContentProps {
  title: string;
  subtitle: string;
  buttonText: string;
}

const SliderContent = ({ title, subtitle, buttonText }: SliderContentProps) => {
  return (
    <div className={classes.sliderContent}>
      <div className={classes.sliderTitle}>{title}</div>
      <div className={classes.sliderSubtitle}>{subtitle}</div>
      <NavLink
        to="/our-products"
        className={({ isActive }) =>
          isActive ? classes.navigationElementActive : classes.navigationElement
        }
      >
        <button
          type="button"
          className={classes.sliderButton}
          data-cy="get-started-button"
        >
          {buttonText}
        </button>
      </NavLink>
    </div>
  );
};

export default SliderContent;
