import { useState } from 'react';
import hQ from '../../assets/QAndQTSide.png';
import vQ from '../../assets/DataStructure.png';
import classes from './PreOrderFormModal.module.css';
import XIcon from '../../assets/svg/XIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { freshdeskSendEmailAction } from '../../store/freshdesk/freshdesk.actions';
import { FreshdeskEmailType } from '../../utils/constants/freshdesk';

interface PreOrderFormModalProps {
  hideMessageScreen: () => void;
  type: FreshdeskEmailType;
}
const PreOrderFormModal = ({
  hideMessageScreen,
  type,
}: PreOrderFormModalProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { loadingFreshdesk } = useAppSelector((state) => state.freshdesk);

  const dispatch = useAppDispatch();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (name === '' && email === '') {
      return;
    }
    dispatch(freshdeskSendEmailAction({ name, email }, type));
    hideMessageScreen();
  };

  return (
    <div className={classes.modalContainer}>
      <style>{`body {overflow: hidden;}`}</style>
      <div className={classes.card}>
        <div className={classes.closeButtonContainer}>
          <button type="button" onClick={() => hideMessageScreen()}>
            <XIcon size={14} fill="#5C656B" />
          </button>
        </div>
        <h2>
          {type === FreshdeskEmailType.ORDER_HQ ? 'Hardware' : 'Virtual'} Q is
          coming soon
        </h2>
        <div className={classes.imageContainer}>
          {type === FreshdeskEmailType.ORDER_HQ ? (
            <img src={hQ} alt="Hardware Q" />
          ) : (
            <img src={vQ} alt="Virtual Q" />
          )}
        </div>
        <p>Add your details if you want to be included on our waiting list.</p>
        <div className={classes.modalFormContainer}>
          <form
            method="post"
            className={classes.contactForm}
            onSubmit={handleSubmit}
          >
            <h3>Full Name</h3>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="e.g. John Doe"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <h3>Email</h3>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="e.g. johndoe@gmail.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={classes.submitContainer}>
              <button
                type="submit"
                disabled={!name || !email || loadingFreshdesk}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PreOrderFormModal;
