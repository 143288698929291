import SuccessMessageIcon from '../../assets/svg/SuccessMessageIcon';
import { useAppSelector } from '../../hooks/redux';
import classes from './FormMessage.module.css';

interface FormMessageProps {
  hideMessageScreen: () => void;
}

const FormMessage = ({ hideMessageScreen }: FormMessageProps) => {
  const { error } = useAppSelector((state) => state.freshdesk);
  return (
    <div className={classes.messageContainer}>
      <style>{`body {overflow: hidden;}`}</style>
      <div className={classes.card}>
        {!error ? (
          <h2>Your ticket has been sent!</h2>
        ) : (
          <h2>{`Sorry your ticket couldn't be sent!`}</h2>
        )}
        {!error ? (
          <p>
            Thank you for reaching us! Our support team will follow up with you
            via email as soon as possible.
          </p>
        ) : (
          <p className={classes.errorColor}>
            Please click the button below to re-send your message.
          </p>
        )}
        <div className={classes.iconContainer}>
          <SuccessMessageIcon />
        </div>
        <div className={classes.buttonContainer}>
          <button type="button" onClick={() => hideMessageScreen()}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormMessage;
