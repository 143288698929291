import { useState } from 'react';
import PlusIcon from '../../assets/svg/PlusIcon';
import MinusIcon from '../../assets/svg/MinusIcon';
import classes from './FAQ.module.css';

interface FAQAccordionProps {
  faqTitle: string;
  faqText: string;
  active: boolean;
}

const FAQAccordion = ({
  faqTitle,
  faqText,
  active = false,
}: FAQAccordionProps) => {
  const [isActive, setActive] = useState(active);

  const toggleText = () => {
    setActive(!isActive);
  };

  return (
    <div className={classes.faqAccordion}>
      <div className={classes.faqAccordionContent}>
        <h3>{faqTitle}</h3>
        <p className={isActive ? classes.visible : ''}>{faqText}</p>
      </div>
      <div
        className={
          isActive
            ? [classes.faqAccordionToggle, classes.toggleOn].join(' ')
            : [classes.faqAccordionToggle, classes.toggleOff].join(' ')
        }
      >
        <button type="button" onClick={toggleText}>
          {isActive ? (
            <MinusIcon size={20} fill="#FFFFFF" />
          ) : (
            <PlusIcon size={20} fill="#5C656B" />
          )}
          {/* <MinusIcon size={20} fill="#FFFFFF" /> */}
        </button>
      </div>
    </div>
  );
};

export default FAQAccordion;
