import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import DownloadInstructions from '../DownloadInstructions/DownloadInstructions';
import ContactUs from '../ContactUs/ContactUs';
import GetYourQ from '../GetYourQ/GetYourQ';
import Home from '../Home/Home';
import OurProducts from '../OurProducts/OurProducts';
import SuccessCheckout from '../SuccessCheckout/SuccessCheckout';

export const Router: FC = () => (
  <BrowserRouter>
    <NavBar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/our-products" element={<OurProducts />} />
      <Route path="/get-your-q" element={<GetYourQ />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/success-checkout" element={<SuccessCheckout />} />
      <Route path="/claim/*" element={<DownloadInstructions />} />
      <Route path="/invitation/*" element={<DownloadInstructions />} />
    </Routes>
  </BrowserRouter>
);
