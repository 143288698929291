import { useState } from 'react';
import Arrow from '../../assets/svg/Arrow';
import classes from './Accordion.module.css';

interface DataContent {
  title: string;
  content: string;
}

interface AccordionProps {
  data: DataContent[];
}

const Accordion = ({ data }: AccordionProps) => {
  const [selected, setSelected] = useState(0);

  const toggle = (index: number) => {
    if (selected === index) {
      return setSelected(-1);
    }
    return setSelected(index);
  };
  return (
    <div className={classes.accordion} data-cy="accordion">
      {data.map((item, index) => (
        <div key={item.title}>
          <div>
            <button
              type="button"
              className={classes.accordionButton}
              onClick={() => toggle(index)}
              onKeyUp={() => toggle(index)}
            >
              {item.title}
              <div
                className={
                  selected === index
                    ? classes.arrowRotateUp
                    : classes.arrowRotateDown
                }
              >
                <span className={classes.arrow}>
                  <Arrow width="26" height="17" fill="#108B54" />
                </span>
                <span className={classes.arrowMobile}>
                  <Arrow width="15" height="15" fill="#108B54" />
                </span>
              </div>
            </button>
          </div>
          <div
            className={
              selected === index ? classes.contentShow : classes.content
            }
          >
            <div className={classes.contentShow}>{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
