import { updateObject } from '../../utils';
import { ISnipcartProduct } from '../../utils/constants/snipcart';
import { ActionTypes } from '../constants/action.types';
import { ISnipcartAction, ISnipcartState } from './types';

const snipcartProduct: ISnipcartProduct | null = null;

export const initialState: ISnipcartState = {
  product: snipcartProduct,
  loadingSnipcart: false,
};

export const snipcartReducer = (
  state = initialState,
  action?: ISnipcartAction
) => {
  switch (action?.type) {
    case ActionTypes.GET_SNIPCART_START:
      return updateObject(state, { loadingSnipcart: true });
    case ActionTypes.GET_SNIPCART_SUCCESS:
      return updateObject(state, {
        loadingSnipcart: false,
        product: action.product,
      });
    case ActionTypes.GET_SNIPCART_FAIL:
      return updateObject(state, {
        loadingSnipcart: false,
        error: action.error,
      });
    default:
      return state;
  }
};
