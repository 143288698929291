import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './DownloadInstructions.module.css';
import AppStoreBadgeEn from '../../assets/svg/AppStoreBadgeEn';
import DownloadApp from '../../assets/svg/DownloadApp';
import PlayStoreBadgeEn from '../../assets/svg/PlayStoreBadgeEn';
import QRCode from '../../assets/svg/QRCode';
import Button from '../../components/Button/Button';
import { instructionsData } from '../../utils/constants';
import Footer from '../../components/Footer/Footer';

const DownloadInstructions: FC = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (userAgent.includes('iPhone') || userAgent.includes('Android')) {
    const redirectStore = userAgent.includes('iPhone')
      ? process.env.REACT_APP_QUOTIENT_APP_STORE
      : process.env.REACT_APP_QUOTIENT_PLAY_STORE;
    window.location.replace(redirectStore as string);
    return null;
  }
  const appStore = process.env.REACT_APP_QUOTIENT_APP_STORE as string;
  const playStore = process.env.REACT_APP_QUOTIENT_PLAY_STORE as string;

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.messageContainer}>
          <h1>{instructionsData[0].title}</h1>
          <p>{instructionsData[0].content}</p>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <DownloadApp />
          </div>
          <div className={classes.col}>
            <div className={classes.row}>
              <div className={classes.col}>
                <QRCode />
              </div>
              <div className={classes.col}>
                <a href={playStore} target="_BLANK" rel="noreferrer">
                  <PlayStoreBadgeEn size={40} />
                </a>
                <a href={appStore} target="_BLANK" rel="noreferrer">
                  <AppStoreBadgeEn size={40} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <NavLink to="/" end>
          <Button value="Back to home" />
        </NavLink>
      </div>
      <Footer />
    </div>
  );
};

export default DownloadInstructions;
