import { useState } from 'react';
import { faqData } from '../../utils/constants';
import classes from './FAQ.module.css';
import FAQAccordion from './FAQAccordion';

const FAQ = () => {
  const [isViewMore, setViewMore] = useState(false);
  const maxVisible: number = 5;

  const viewMore = () => {
    setViewMore(true);
  };

  return (
    <div className={classes.faqContainer}>
      <h1 className={classes.faqTitle}>FAQ</h1>
      <div className={classes.faqAccordionContainer} data-cy="faqOptions">
        {faqData.map((faq, faqIndex) => (
          <div key={faq.faqTitle}>
            {faqIndex < (isViewMore ? faqData.length : maxVisible) ? (
              <FAQAccordion
                faqTitle={faq.faqTitle}
                faqText={faq.faqText}
                active={faq.faqActive}
              />
            ) : null}
          </div>
        ))}
        <div className={classes.btnViewMoreContainer}>
          {isViewMore ? null : (
            <button
              type="button"
              className={classes.btnViewMore}
              onClick={viewMore}
            >
              View more
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
