const SuccessMessageIcon = () => {
  return (
    <svg
      width={229}
      height={201}
      viewBox="0 0 229 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1226_68507)">
        <path
          d="M11.0501 68.4947L0.00828839 62.2575C-0.140654 55.5844 1.33691 48.9756 4.31267 43.0051C6.85841 54.5931 20.2558 60.1063 26.4371 70.224C28.341 73.3944 29.509 76.9539 29.8546 80.6386C30.2003 84.3233 29.7148 88.0388 28.434 91.5097L29.1602 100.494C23.4534 98.78 18.185 95.8454 13.718 91.8925C9.25098 87.9397 5.69153 83.0626 3.28492 77.5973C1.96509 74.5178 1.0372 71.284 0.523135 67.972C5.6713 68.5214 11.0501 68.4947 11.0501 68.4947Z"
          fill="#F2F2F2"
        />
        <path
          d="M209.544 181.966L209.883 174.331C213.413 172.438 217.349 171.439 221.351 171.419C215.843 175.935 216.532 184.641 212.797 190.715C211.618 192.6 210.031 194.195 208.154 195.382C206.276 196.568 204.157 197.316 201.952 197.569L197.342 200.399C196.71 196.863 196.849 193.232 197.751 189.755C198.653 186.278 200.296 183.038 202.566 180.259C203.855 178.713 205.333 177.335 206.966 176.159C208.069 179.079 209.544 181.966 209.544 181.966Z"
          fill="#F2F2F2"
        />
        <path
          d="M197.668 196.47L193.494 196.47L191.509 180.325L197.669 180.325L197.668 196.47Z"
          fill="#FFB6B6"
        />
        <path
          d="M198.732 200.528L185.274 200.527V200.357C185.275 198.963 185.826 197.627 186.809 196.642C187.791 195.657 189.123 195.103 190.513 195.103H190.513L192.971 193.233L197.558 195.104L198.733 195.104L198.732 200.528Z"
          fill="#2F2E41"
        />
        <path
          d="M178.277 196.47L174.104 196.47L172.118 180.325L178.278 180.325L178.277 196.47Z"
          fill="#FFB6B6"
        />
        <path
          d="M179.342 200.528L165.884 200.527V200.357C165.884 198.963 166.436 197.627 167.418 196.642C168.4 195.657 169.733 195.103 171.122 195.103H171.122L173.581 193.233L178.167 195.104L179.342 195.104L179.342 200.528Z"
          fill="#2F2E41"
        />
        <path
          d="M176.47 108.202L203.618 108.883C208.774 111.691 208.853 117.77 207.163 124.987C207.163 124.987 207.734 129.277 206.593 130.421C205.452 131.565 204.882 131.565 205.452 133.567C206.023 135.569 203.009 138.838 203.375 139.205C203.741 139.572 204.597 141.574 204.597 141.574L201.745 157.875C201.745 157.875 199.749 186.187 199.179 186.759C198.609 187.331 198.038 186.759 198.609 188.189C199.179 189.619 199.749 189.047 199.179 189.619C198.674 190.165 198.198 190.738 197.753 191.335H190.859C190.859 191.335 190.339 188.475 190.339 188.189C190.339 187.903 189.769 186.187 189.769 185.901C189.769 185.615 190.273 185.11 190.273 185.11C190.447 184.528 190.565 183.931 190.624 183.327C190.624 182.755 192.335 160.735 192.335 160.735L188.913 132.137L180.644 160.163C180.644 160.163 180.644 184.757 180.073 185.329C179.503 185.901 179.503 185.615 179.788 186.759C180.073 187.903 181.214 187.617 180.359 188.475C179.503 189.333 179.218 187.903 179.503 189.333L179.788 190.763L171.234 190.886C171.234 190.886 170.093 188.475 170.663 187.617C171.234 186.759 171.199 186.993 170.504 185.589C169.808 184.185 169.523 183.899 169.808 183.613C170.093 183.327 170.093 181.806 170.093 181.806L171.519 154.443C171.519 154.443 171.804 125.559 171.804 124.701C171.825 124.26 171.744 123.819 171.567 123.415V122.25L172.659 118.124L176.47 108.202Z"
          fill="#2F2E41"
        />
        <path
          d="M192.779 51.5886C197.437 51.5886 201.214 47.8012 201.214 43.1291C201.214 38.4571 197.437 34.6697 192.779 34.6697C188.12 34.6697 184.344 38.4571 184.344 43.1291C184.344 47.8012 188.12 51.5886 192.779 51.5886Z"
          fill="#FFB6B6"
        />
        <path
          d="M188.562 57.1797C186.745 58.2632 185.658 60.2539 185.034 62.2796C183.878 66.03 183.183 69.9076 182.963 73.8267L182.304 85.5598L174.141 110.543C181.215 116.546 207.807 111.907 207.807 111.907C207.807 111.907 208.624 111.634 207.807 110.816C206.991 109.997 206.196 110.722 207.013 109.904C207.829 109.085 207.266 109.997 206.994 109.178C206.722 108.36 206.994 108.906 207.266 108.633C207.538 108.36 205.16 105.904 205.16 105.904L207.337 91.5633L210.058 62.6374C206.792 58.5441 200.226 56.1311 200.226 56.1311L198.529 53.0681L190.045 53.7488L188.562 57.1797Z"
          fill="#E6E6E6"
        />
        <path
          d="M187.404 38.1645C187.731 38.167 188.055 38.2098 188.371 38.2918C188.994 38.4419 189.627 38.5483 190.265 38.6102C192.217 38.7944 194.052 39.6296 195.476 40.9823C196.9 42.3351 197.831 44.1273 198.12 46.0729C198.61 45.6443 199.725 44.856 200.639 45.5886C200.653 45.6003 200.662 45.6063 200.694 45.591C201.301 45.3021 202.378 41.7469 202.411 38.6166C202.428 36.9552 202.168 34.8336 200.828 34.1617L200.757 34.1263L200.739 34.0494C200.649 33.6757 199.649 33.0377 197.911 32.5412C194.752 31.6388 190.043 29.881 187.392 32.4679C187.191 33.34 186.538 35.2063 185.879 35.4179C185.148 35.652 184.518 35.8541 184.418 36.7525C184.335 38.0309 184.341 39.3136 184.436 40.5912C184.684 39.8094 185.17 39.1251 185.825 38.6348C186.291 38.32 186.842 38.1559 187.404 38.1645Z"
          fill="#2F2E41"
        />
        <path
          d="M171.704 84.9066L185.483 76.0568L178.543 66.8048L171.691 75.2587L150.339 76.572C149.777 76.0152 149.066 75.6344 148.293 75.4759C147.519 75.3174 146.716 75.3881 145.982 75.6793C145.248 75.9706 144.614 76.4698 144.157 77.1162C143.701 77.7625 143.442 78.528 143.412 79.3194C143.382 80.1108 143.582 80.8938 143.988 81.573C144.395 82.2522 144.989 82.7982 145.699 83.1445C146.409 83.4908 147.205 83.6223 147.988 83.5231C148.771 83.424 149.509 83.0983 150.111 82.5859L171.704 84.9066Z"
          fill="#FFB6B6"
        />
        <path
          d="M198.272 63.7043C199.072 68.558 183.97 77.8265 183.97 77.8265C183.97 76.6873 174.956 85.2878 174.549 84.343C173.393 81.6576 172.58 75.2973 170.964 74.2793C170.04 73.6971 176.529 68.2279 176.529 68.2279C176.529 68.2279 179.926 64.7961 184.383 60.4673C185.621 59.22 187.153 58.3071 188.837 57.8134C190.521 57.3197 192.302 57.2614 194.015 57.6438C194.015 57.6438 197.472 58.8506 198.272 63.7043Z"
          fill="#E6E6E6"
        />
        <path
          d="M226.672 87.2297L217.558 73.6016L208.481 80.7559L217.054 87.4479L218.815 108.829C218.272 109.403 217.908 110.124 217.766 110.903C217.625 111.683 217.712 112.486 218.018 113.216C218.324 113.946 218.835 114.571 219.489 115.015C220.143 115.459 220.911 115.703 221.701 115.716C222.491 115.729 223.267 115.512 223.935 115.09C224.604 114.669 225.136 114.061 225.466 113.342C225.796 112.622 225.91 111.822 225.795 111.039C225.679 110.255 225.339 109.522 224.815 108.93L226.672 87.2297Z"
          fill="#FFB6B6"
        />
        <path
          d="M204.973 61.0404C209.795 60.1358 219.354 75.0817 219.354 75.0817C218.218 75.1061 226.983 83.9616 226.05 84.3895C223.397 85.6058 217.074 86.5556 216.093 88.1972C215.532 89.136 209.943 82.7454 209.943 82.7454C209.943 82.7454 206.45 79.4123 202.04 75.0345C200.77 73.8201 199.828 72.3032 199.3 70.6251C198.772 68.9471 198.676 67.1624 199.021 65.4371C199.021 65.4371 200.151 61.945 204.973 61.0404Z"
          fill="#E6E6E6"
        />
        <path
          d="M229 200.595C229.001 200.648 228.99 200.701 228.97 200.75C228.95 200.799 228.92 200.844 228.882 200.882C228.845 200.919 228.8 200.949 228.751 200.969C228.702 200.99 228.65 201 228.597 201H133.48C133.373 201 133.27 200.957 133.194 200.881C133.119 200.805 133.076 200.702 133.076 200.595C133.076 200.488 133.119 200.385 133.194 200.309C133.27 200.233 133.373 200.19 133.48 200.19H228.597C228.65 200.19 228.702 200.2 228.751 200.22C228.8 200.241 228.845 200.271 228.882 200.308C228.92 200.346 228.95 200.391 228.97 200.44C228.99 200.489 229.001 200.542 229 200.595Z"
          fill="#CCCCCC"
        />
        <path
          d="M140.885 77.8655H25.4481C23.6445 77.8675 21.9155 78.5869 20.6402 79.8659C19.3649 81.1449 18.6475 82.879 18.6455 84.6877V156.766C18.6476 158.575 19.3649 160.309 20.6402 161.588C21.9155 162.867 23.6445 163.586 25.4481 163.588H140.885C142.688 163.586 144.418 162.867 145.693 161.588C146.968 160.309 147.686 158.575 147.688 156.766V84.6877C147.686 82.879 146.968 81.1449 145.693 79.8659C144.418 78.5869 142.689 77.8675 140.885 77.8655Z"
          fill="#108B54"
        />
        <path
          d="M140.885 77.8655H25.4481C23.6445 77.8675 21.9155 78.5869 20.6402 79.8659C19.3649 81.1449 18.6475 82.879 18.6455 84.6877V156.766C18.6476 158.575 19.3649 160.309 20.6402 161.588C21.9155 162.867 23.6445 163.586 25.4481 163.588H140.885C142.688 163.586 144.418 162.867 145.693 161.588C146.968 160.309 147.686 158.575 147.688 156.766V84.6877C147.686 82.879 146.968 81.1449 145.693 79.8659C144.418 78.5869 142.689 77.8675 140.885 77.8655ZM143.117 129.64C143.09 136.978 140.196 144.013 135.056 149.236C129.916 154.458 122.94 157.453 115.624 157.576C103.911 157.729 122.105 157.789 107.978 157.789C75.6739 157.789 41.9901 157.479 29.4223 157.278C27.7687 157.244 26.194 156.563 25.0344 155.38C23.8747 154.198 23.2221 152.607 23.2157 150.949V84.6931C23.2163 84.0991 23.4515 83.5296 23.8698 83.109C24.288 82.6885 24.8553 82.4513 25.4476 82.4491H140.88C141.472 82.4498 142.04 82.6856 142.459 83.105C142.879 83.5244 143.115 84.0932 143.117 84.6871V129.64Z"
          fill="#3F3D56"
        />
        <path
          d="M126.676 36.3604H38.7248C37.9467 36.3615 37.1878 36.6034 36.5518 37.0532C35.9159 37.5029 35.4338 38.1386 35.1715 38.8733L21.5881 77.0926C21.3854 77.6631 21.3221 78.274 21.4038 78.874C21.4854 79.4741 21.7094 80.0457 22.0571 80.5409C22.4047 81.0361 22.8658 81.4403 23.4016 81.7197C23.9374 81.999 24.5323 82.1452 25.1361 82.1461L143.093 82.356H143.098C143.722 82.356 144.337 82.2006 144.886 81.9038C145.436 81.607 145.903 81.178 146.247 80.6554C146.591 80.1327 146.8 79.5327 146.855 78.9092C146.911 78.2856 146.811 77.658 146.565 77.0825L146.359 77.1708L146.565 77.0825L130.143 38.6533C129.853 37.9724 129.369 37.3921 128.753 36.9844C128.136 36.5768 127.414 36.3598 126.676 36.3604Z"
          fill="#3F3D56"
        />
        <path
          d="M146.87 77.9125C146.869 78.425 146.766 78.9321 146.564 79.4032L130.143 108.985C129.851 109.664 129.367 110.243 128.751 110.65C128.134 111.057 127.413 111.274 126.675 111.275H38.7256C37.9471 111.275 37.1879 111.033 36.5514 110.583C35.9149 110.134 35.4322 109.498 35.1692 108.764L21.5884 79.393C21.4219 78.9251 21.349 78.4288 21.3739 77.9326C21.3988 77.4365 21.5209 76.95 21.7333 76.5012C21.9457 76.0524 22.2443 75.65 22.6118 75.3169C22.9794 74.9839 23.4089 74.7268 23.8756 74.5603C24.2793 74.4141 24.7053 74.3392 25.1346 74.339L39.5434 74.3118L45.4244 74.3016L122.81 74.1655L128.694 74.1553L143.093 74.1281H143.096C144.097 74.1282 145.057 74.527 145.764 75.2367C146.472 75.9464 146.87 76.9089 146.87 77.9125Z"
          fill="#3F3D56"
        />
        <path
          d="M34.1022 131.932C35.8462 131.932 59.7958 133.4 59.7958 135.149C59.7958 136.899 35.8462 138.266 34.1022 138.266C33.2647 138.266 32.4615 137.933 31.8692 137.339C31.277 136.745 30.9443 135.939 30.9443 135.099C30.9443 134.26 31.277 133.454 31.8692 132.86C32.4615 132.266 33.2647 131.932 34.1022 131.932Z"
          fill="#3F3D56"
        />
        <path
          d="M121.199 13.1097H47.0402C45.0528 13.112 43.1474 13.9048 41.7421 15.3142C40.3368 16.7236 39.5462 18.6345 39.5439 20.6277V111.275H126.676C127.391 111.278 128.092 111.073 128.695 110.686V20.6277C128.692 18.6345 127.902 16.7236 126.497 15.3142C125.091 13.9049 123.186 13.112 121.199 13.1097Z"
          fill="#E6E6E6"
        />
        <path
          d="M92.8932 19.2052H52.2525C50.4428 19.207 48.7077 19.9285 47.4277 21.2115C46.1478 22.4946 45.4275 24.2344 45.4248 26.0493V111.275H122.81V49.2089C122.801 41.2543 119.646 33.628 114.038 28.0032C108.429 22.3784 100.825 19.2144 92.8932 19.2052Z"
          fill="white"
        />
        <path
          d="M109.626 103.706H58.6081C58.1671 103.706 57.7442 103.882 57.4323 104.195C57.1205 104.507 56.9453 104.932 56.9453 105.374C56.9453 105.816 57.1205 106.24 57.4323 106.553C57.7442 106.866 58.1671 107.042 58.6081 107.042H109.626C110.067 107.042 110.49 106.866 110.802 106.553C111.114 106.24 111.289 105.816 111.289 105.374C111.289 104.932 111.114 104.507 110.802 104.195C110.49 103.882 110.067 103.706 109.626 103.706Z"
          fill="#E4E4E4"
        />
        <path
          d="M109.627 97.1107H58.6089C58.1674 97.1107 57.744 97.2866 57.4319 97.5997C57.1197 97.9127 56.9443 98.3373 56.9443 98.78C56.9443 99.2228 57.1197 99.6474 57.4319 99.9604C57.744 100.274 58.1674 100.449 58.6089 100.449H109.627C110.068 100.449 110.492 100.274 110.804 99.9604C111.116 99.6474 111.291 99.2228 111.291 98.78C111.291 98.3373 111.116 97.9127 110.804 97.5997C110.492 97.2866 110.068 97.1107 109.627 97.1107Z"
          fill="#E4E4E4"
        />
        <path
          d="M80.7236 90.5184H58.6081C58.1671 90.5184 57.7442 90.6941 57.4323 91.0069C57.1205 91.3196 56.9453 91.7438 56.9453 92.1861C56.9453 92.6283 57.1205 93.0525 57.4323 93.3652C57.7442 93.678 58.1671 93.8537 58.6081 93.8537H80.7236C81.1646 93.8537 81.5876 93.678 81.8994 93.3652C82.2112 93.0525 82.3864 92.6283 82.3864 92.1861C82.3864 91.7438 82.2112 91.3196 81.8994 91.0069C81.5876 90.6941 81.1646 90.5184 80.7236 90.5184Z"
          fill="#E4E4E4"
        />
        <path
          d="M109.627 76.7078H58.6089C58.1674 76.7078 57.744 76.8836 57.4319 77.1967C57.1197 77.5098 56.9443 77.9344 56.9443 78.3771C56.9443 78.8198 57.1197 79.2444 57.4319 79.5575C57.744 79.8705 58.1674 80.0464 58.6089 80.0464H109.627C110.068 80.0464 110.492 79.8705 110.804 79.5575C111.116 79.2444 111.291 78.8198 111.291 78.3771C111.291 77.9344 111.116 77.5098 110.804 77.1967C110.492 76.8836 110.068 76.7078 109.627 76.7078Z"
          fill="#E4E4E4"
        />
        <path
          d="M109.626 70.1155H58.6081C58.1671 70.1155 57.7442 70.2912 57.4323 70.6039C57.1205 70.9167 56.9453 71.3408 56.9453 71.7831C56.9453 72.2254 57.1205 72.6496 57.4323 72.9623C57.7442 73.275 58.1671 73.4507 58.6081 73.4507H109.626C110.067 73.4507 110.49 73.275 110.802 72.9623C111.114 72.6496 111.289 72.2254 111.289 71.7831C111.289 71.3408 111.114 70.9167 110.802 70.6039C110.49 70.2912 110.067 70.1155 109.626 70.1155Z"
          fill="#E4E4E4"
        />
        <path
          d="M80.7243 63.5199H58.6089C58.1674 63.5199 57.744 63.6958 57.4319 64.0088C57.1197 64.3219 56.9443 64.7465 56.9443 65.1892C56.9443 65.632 57.1197 66.0566 57.4319 66.3696C57.744 66.6827 58.1674 66.8586 58.6089 66.8586H80.7243C81.1658 66.8586 81.5892 66.6827 81.9013 66.3696C82.2135 66.0566 82.3888 65.632 82.3888 65.1892C82.3888 64.7465 82.2135 64.3219 81.9013 64.0088C81.5892 63.6958 81.1658 63.5199 80.7243 63.5199Z"
          fill="#E4E4E4"
        />
        <path
          d="M109.627 49.7091H58.6089C58.1674 49.7091 57.744 49.885 57.4319 50.198C57.1197 50.5111 56.9443 50.9357 56.9443 51.3784C56.9443 51.8212 57.1197 52.2458 57.4319 52.5588C57.744 52.8719 58.1674 53.0478 58.6089 53.0478H109.627C110.068 53.0478 110.492 52.8719 110.804 52.5588C111.116 52.2458 111.291 51.8212 111.291 51.3784C111.291 50.9357 111.116 50.5111 110.804 50.198C110.492 49.885 110.068 49.7091 109.627 49.7091Z"
          fill="#108B54"
        />
        <path
          d="M109.627 43.1169H58.6089C58.1674 43.1169 57.744 43.2928 57.4319 43.6059C57.1197 43.9189 56.9443 44.3435 56.9443 44.7863C56.9443 45.229 57.1197 45.6536 57.4319 45.9667C57.744 46.2797 58.1674 46.4556 58.6089 46.4556H109.627C110.068 46.4556 110.492 46.2797 110.804 45.9667C111.116 45.6536 111.291 45.229 111.291 44.7863C111.291 44.3435 111.116 43.9189 110.804 43.6059C110.492 43.2928 110.068 43.1169 109.627 43.1169Z"
          fill="#108B54"
        />
        <path
          d="M80.7243 36.5212H58.6089C58.1674 36.5212 57.744 36.6971 57.4319 37.0102C57.1197 37.3232 56.9443 37.7478 56.9443 38.1906C56.9443 38.6333 57.1197 39.0579 57.4319 39.371C57.744 39.684 58.1674 39.8599 58.6089 39.8599H80.7243C81.1658 39.8599 81.5892 39.684 81.9013 39.371C82.2135 39.0579 82.3888 38.6333 82.3888 38.1906C82.3888 37.7478 82.2135 37.3232 81.9013 37.0102C81.5892 36.6971 81.1658 36.5212 80.7243 36.5212Z"
          fill="#108B54"
        />
        <path
          d="M125.914 25.9761C133.067 25.9761 138.865 20.1612 138.865 12.9881C138.865 5.81495 133.067 0 125.914 0C118.762 0 112.964 5.81495 112.964 12.9881C112.964 20.1612 118.762 25.9761 125.914 25.9761Z"
          fill="#108B54"
        />
        <path
          d="M124.148 19.7477C123.924 19.7477 123.704 19.6955 123.504 19.5952C123.304 19.4949 123.13 19.3492 122.995 19.1697L119.462 14.4452C119.348 14.2935 119.265 14.1206 119.218 13.9366C119.17 13.7526 119.16 13.561 119.186 13.3728C119.213 13.1847 119.276 13.0036 119.372 12.84C119.469 12.6764 119.597 12.5335 119.748 12.4194C119.9 12.3054 120.072 12.2225 120.256 12.1755C120.44 12.1284 120.631 12.1182 120.818 12.1453C121.006 12.1725 121.186 12.2365 121.349 12.3336C121.512 12.4308 121.654 12.5593 121.767 12.7116L124.079 15.8023L130.015 6.87151C130.227 6.55268 130.557 6.33136 130.932 6.25621C131.306 6.18106 131.695 6.25826 132.013 6.4708C132.331 6.68335 132.552 7.01385 132.627 7.38958C132.702 7.76532 132.625 8.15551 132.413 8.47434L125.347 19.1044C125.219 19.2966 125.047 19.4553 124.845 19.5672C124.644 19.6791 124.418 19.7408 124.188 19.7473C124.175 19.7475 124.161 19.7477 124.148 19.7477Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1226_68507">
          <rect width="229" height="201" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessMessageIcon;
