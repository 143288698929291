/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { Buffer } from 'buffer';

const snipcartAxios = axios.create({});

export const snipcartConfig = async (config: AxiosRequestConfig) => {
  const buffer = `${process.env.REACT_APP_SNIPCART_API_SECRET_KEY}:`;
  const basicAuth = Buffer.from(buffer).toString('base64');

  if (!config.headers) {
    config.headers = {};
  }

  config.headers.Accept = 'application/json';
  config.headers.Authorization = `Basic ${basicAuth}`;
  config.headers['Content-Type'] = 'application/json';

  return config;
};

snipcartAxios.interceptors.request.use(snipcartConfig);

export { snipcartAxios };
