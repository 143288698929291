import { FC } from 'react';
import { Router } from './pages/Router/router';

const App: FC = () => {
  return (
    <div>
      <Router />
    </div>
  );
};

export default App;
