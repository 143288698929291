interface ArrowLeftProps {
  size: number;
  color: string;
}

const ArrowLeft = ({ size = 38, color = '#FFFFFF' }: ArrowLeftProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <path
        d="M18.5 1.5L1 19L18.5 36.5M1 19H41"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
