import { ThunkDispatch } from 'redux-thunk';
import { SnipcartService } from '../../services/snipcart.service';
import { ISnipcartProduct } from '../../utils/constants/snipcart';
import { ActionTypes } from '../constants/action.types';
import { RootState } from '../types';
import { ISnipcartAction } from './types';

const snipcartService = new SnipcartService();

export const snipcartGetProductAction = Object.assign(
  (id: string) =>
    async (dispatch: ThunkDispatch<RootState, void, ISnipcartAction>) => {
      dispatch(snipcartGetProductAction.start());
      try {
        const product = await snipcartService.getProduct(id);
        dispatch(snipcartGetProductAction.success(product));
      } catch (error) {
        const errorMessage =
          (error as Error)?.message ??
          (error as string) ??
          'Something went wrong';
        dispatch(snipcartGetProductAction.fail(errorMessage));
      }
    },
  {
    start: (): ISnipcartAction => ({ type: ActionTypes.GET_SNIPCART_START }),
    success: (product: ISnipcartProduct): ISnipcartAction => ({
      type: ActionTypes.GET_SNIPCART_SUCCESS,
      product,
    }),
    fail: (error: string): ISnipcartAction => ({
      type: ActionTypes.GET_SNIPCART_FAIL,
      error,
    }),
  }
);
