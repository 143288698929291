const GradientDotsMobile = () => {
  return (
    <svg viewBox="0 0 320 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_623_66041)">
        <rect width="14" height="14" transform="translate(0 -4)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 1)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 10)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 15)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 24)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 29)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 38)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 43)"
          fill="#2FCF87"
        />
        <rect
          y="-6"
          width="320"
          height="56"
          fill="url(#paint0_linear_623_66041)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_623_66041"
          x1="160"
          y1="50"
          x2="160"
          y2="-6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.92" />
          <stop offset="1" stopColor="white" stopOpacity="0.25" />
        </linearGradient>
        <clipPath id="clip0_623_66041">
          <rect width="320" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GradientDotsMobile;
