export const updateObject = <T extends object>(
  oldObject: T,
  newValues: Partial<T>
): T => {
  return { ...oldObject, ...newValues };
};

export const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
