import { NavLink } from 'react-router-dom';
import AppStoreBadgeEn from '../../assets/svg/AppStoreBadgeEn';
import PlayStoreBadgeEn from '../../assets/svg/PlayStoreBadgeEn';
import classes from './CTABanner.module.css';

const CTABanner = () => {
  const appStore = process.env.REACT_APP_QUOTIENT_APP_STORE as string;
  const playStore = process.env.REACT_APP_QUOTIENT_PLAY_STORE as string;
  return (
    <div className={classes.ctaContainer}>
      <div>
        <h2 className={classes.ctaTitle}>
          The future of social networking is here
        </h2>
      </div>
      <div className={classes.ctaButtonContainer}>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? classes.navigationElementActive
              : classes.navigationElement
          }
          to="/our-products"
        >
          <button type="button" className={classes.ctaButton}>
            Get Started Now
          </button>
        </NavLink>
      </div>
      <div className={classes.badgesContainer}>
        <a href={appStore} target="_BLANK" rel="noreferrer">
          <AppStoreBadgeEn size={18} />
        </a>
        <a href={playStore} target="_BLANK" rel="noreferrer">
          <PlayStoreBadgeEn size={18} />
        </a>
      </div>
    </div>
  );
};

export default CTABanner;
