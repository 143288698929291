import { FC } from 'react';
import FAQ from '../../components/FAQ/FAQ';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import classes from './ContactUs.module.css';

const ContactUs: FC = () => (
  <div className={classes.container}>
    <ContactForm />
    <FAQ />
    <Footer />
  </div>
);

export default ContactUs;
