interface ArrowProps {
  width: string;
  height: string;
  fill: string;
}

const Arrow = ({ width, height, fill }: ArrowProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 15" fill="none">
      <path
        d="M3.22167 0.464172L12 9.24251L20.7975 0.464172L23.5 3.16667L12 14.6667L0.5 3.16667L3.22167 0.464172Z"
        fill={fill}
      />
    </svg>
  );
};

export default Arrow;
