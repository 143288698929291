interface ButtonArrowProps {
  size: number;
  color: string;
}

const ButtonArrow = ({ size = 12, color = '#FFFFFF' }: ButtonArrowProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <path
        d="M-0.000116848 6.74994L-0.000116717 5.24994L8.99988 5.24994L4.87488 1.12494L5.93988 0.0599398L11.8799 5.99994L5.93988 11.9399L4.87488 10.8749L8.99988 6.74994L-0.000116848 6.74994Z"
        fill={color}
      />
    </svg>
  );
};

export default ButtonArrow;
