import { SetStateAction, useEffect, useState } from 'react';
import ArrowLeft from '../../assets/svg/ArrowLeft';
import classes from './HeroCarousel.module.css';
import SliderContent from './SliderContent';

interface ImageSliderProps {
  slide1: string;
  slide2: string;
  slide3: string;
  slide4: string;
  slide5: string;
}

// eslint-disable-next-line react/prop-types
const ImageSlider = ({
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
}: ImageSliderProps) => {
  const slides = [slide1, slide2, slide3, slide4, slide5];
  const titles = [
    'With Quotient',
    'Share your files',
    'You can create',
    'You can message',
    'You can share',
  ];
  const subtitles = [
    'create your own private social network.',
    'securely without compromising your private information.',
    'your own authentic experience.',
    'your loved ones from anywhere in the world.',
    'family photos that no one is going to target for marketing.',
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviews = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: SetStateAction<number>) => {
    setCurrentIndex(slideIndex);
  };

  // auto play carousel
  useEffect(() => {
    const slideInterval = setInterval(() => {
      goToNext();
    }, 10000);
    return () => clearInterval(slideInterval);
  });

  // swipe
  let touchstartX: number;
  let touchendX: number;
  const swipeArea = 'swipeArea';
  const getTouchStart = () => {
    document.addEventListener('touchstart', (event) => {
      touchstartX = event.changedTouches[0].screenX;
    });
  };

  const getTouchEnd = () => {
    document.addEventListener('touchend', (event) => {
      touchendX = event.changedTouches[0].screenX;
      if (touchstartX > touchendX) {
        goToNext();
      }
      if (touchstartX < touchendX) {
        goToPreviews();
      }
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={classes.swipeArea}
        onTouchStart={getTouchStart}
        onTouchEnd={getTouchEnd}
        id={swipeArea}
      />
      <div className={classes.sliderParallaxContainer}>
        <div
          className={classes.sliderParallax}
          style={{ transform: `translate(${currentIndex * -100}vw, 0)` }}
        >
          {slides.map((slide, slideIndex) => (
            <div
              key={slide}
              className={
                slideIndex === currentIndex
                  ? classes.imageSlideActive
                  : classes.imageSlide
              }
            >
              <div style={{ backgroundImage: `url(${slides[slideIndex]})` }} />
            </div>
          ))}
        </div>
      </div>
      <SliderContent
        title={titles[currentIndex]}
        subtitle={subtitles[currentIndex]}
        buttonText="Get started"
      />
      <button
        type="button"
        className={classes.btnPreviews}
        onClick={goToPreviews}
        data-cy="leftArrow"
      >
        <ArrowLeft size={38} color="#FFFFFF" />
      </button>
      <button
        type="button"
        className={classes.btnNext}
        data-cy="rightArrow"
        onClick={goToNext}
      >
        <ArrowLeft size={38} color="#FFFFFF" />
      </button>
      <div className={classes.dotContainer}>
        {slides.map((slide, slideIndex) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            className={
              slideIndex === currentIndex ? classes.activeDot : classes.dot
            }
            // eslint-disable-next-line react/no-array-index-key
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            value={slideIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
