interface GalleriesProps {
  width: string;
  height: string;
  fill: string;
}

const Galleries = ({ width, height, fill }: GalleriesProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2 3.8002H20.552C21.236 3.8002 21.8 4.3642 21.8 5.0482V20.5522C21.8 21.2362 21.236 21.8002 20.552 21.8002H5.04801C4.36401 21.8002 3.80001 21.2362 3.80001 20.5522V18.2002H1.44801C0.764012 18.2002 0.200012 17.6362 0.200012 16.9522V1.4482C0.200012 0.764195 0.764012 0.200195 1.44801 0.200195H16.952C17.636 0.200195 18.2 0.764195 18.2 1.4482V3.8002ZM2.60001 15.8002H15.8V2.6002H2.60001V15.8002ZM19.4 19.4002V6.2002H18.2V16.9522C18.2 17.6362 17.636 18.2002 16.952 18.2002H6.20001V19.4002H19.4Z"
        fill={fill}
      />
    </svg>
  );
};

export default Galleries;
