interface QuotientLogoProps {
  size: number;
  color: string;
}

const QuotientLogo = ({ size = 36, color = '#2FCF87' }: QuotientLogoProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9091 16.5072C40.9091 12.4113 37.5887 9.09091 33.4928 9.09091H16.5072C12.4113 9.09091 9.09091 12.4113 9.09091 16.5072V33.4928C9.09091 37.5887 12.4113 40.9091 16.5072 40.9091H31.3704C32.6062 40.9091 33.8225 41.2179 34.9086 41.8075L50 50L25.9569 25.3589L40.1797 34.1201C40.4985 34.3164 40.9091 34.0871 40.9091 33.7127V16.5072ZM7.41627 0C3.32038 0 0 3.32038 0 7.41627V42.5837C0 46.6796 3.32038 50 7.41627 50H50V7.41627C50 3.32038 46.6796 0 42.5837 0H7.41627Z"
        fill={color}
        id="quotient-logo"
      />
    </svg>
  );
};

export default QuotientLogo;
