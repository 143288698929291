const DownloadApp = () => {
  return (
    <svg
      width="339"
      height="293"
      viewBox="0 0 339 293"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1772_70224)">
        <path
          d="M282.766 224.068C284.022 222.173 283.278 219.46 281.105 218.009C278.931 216.557 276.15 216.917 274.894 218.812C273.638 220.707 274.381 223.42 276.555 224.872C278.729 226.323 281.509 225.963 282.766 224.068Z"
          fill="#F2F2F2"
        />
        <path
          d="M257.26 153.333C258.516 151.437 257.772 148.724 255.599 147.273C253.425 145.822 250.645 146.182 249.388 148.077C248.132 149.972 248.875 152.685 251.049 154.136C253.223 155.588 256.003 155.228 257.26 153.333Z"
          fill="#F2F2F2"
        />
        <path
          d="M148.291 34.6649C149.547 32.7697 148.804 30.0568 146.63 28.6054C144.456 27.1541 141.676 27.5139 140.419 29.4091C139.163 31.3042 139.907 34.0171 142.08 35.4685C144.254 36.9198 147.035 36.56 148.291 34.6649Z"
          fill="#F2F2F2"
        />
        <path
          d="M248.914 12.3271C250.17 10.4319 249.427 7.71899 247.253 6.26764C245.079 4.81629 242.299 5.17608 241.042 7.07126C239.786 8.96644 240.53 11.6794 242.703 13.1307C244.877 14.5821 247.658 14.2222 248.914 12.3271Z"
          fill="#F2F2F2"
        />
        <path
          d="M159.733 52.5641C161.782 52.5641 163.443 50.8973 163.443 48.8412C163.443 46.7851 161.782 45.1183 159.733 45.1183C157.684 45.1183 156.023 46.7851 156.023 48.8412C156.023 50.8973 157.684 52.5641 159.733 52.5641Z"
          fill="#3F3D56"
        />
        <path
          d="M325.739 33.9495C327.788 33.9495 329.448 32.2827 329.448 30.2266C329.448 28.1705 327.788 26.5037 325.739 26.5037C323.69 26.5037 322.029 28.1705 322.029 30.2266C322.029 32.2827 323.69 33.9495 325.739 33.9495Z"
          fill="#3F3D56"
        />
        <path
          d="M315.914 292.667V260.925C315.914 260.925 328.397 283.429 315.914 292.667Z"
          fill="#F1F1F1"
        />
        <path
          d="M316.686 292.661L293.09 271.195C293.09 271.195 318.26 277.301 316.686 292.661Z"
          fill="#F1F1F1"
        />
        <path
          d="M119.649 56.508H118.331V20.7156C118.331 15.2215 116.129 9.9524 112.208 6.06747C108.288 2.18254 102.971 3.49129e-06 97.4266 0H20.9045C18.1593 -6.33846e-06 15.4409 0.535812 12.9047 1.57686C10.3684 2.61792 8.06395 4.14382 6.1228 6.06744C4.18164 7.99106 2.64182 10.2747 1.59127 12.7881C0.54072 15.3014 4.65184e-06 17.9952 0 20.7156V217.075C-4.6974e-06 222.569 2.20242 227.838 6.12275 231.723C10.0431 235.608 15.3602 237.791 20.9044 237.791H97.4264C102.971 237.791 108.288 235.608 112.208 231.723C116.128 227.838 118.331 222.569 118.331 217.075V81.9855H119.649V56.508Z"
          fill="#3F3D56"
        />
        <path
          d="M98.2694 5.38954H88.2807C88.7401 6.50555 88.9154 7.71638 88.7913 8.91537C88.6672 10.1144 88.2474 11.2647 87.569 12.2651C86.8906 13.2654 85.9743 14.0851 84.901 14.6519C83.8276 15.2186 82.63 15.5151 81.4137 15.5152H37.5752C36.359 15.5151 35.1614 15.2187 34.088 14.6519C33.0146 14.0851 32.0983 13.2654 31.4199 12.265C30.7415 11.2647 30.3218 10.1143 30.1976 8.91536C30.0735 7.71637 30.2489 6.50554 30.7082 5.38953H21.3788C19.3287 5.38952 17.2987 5.78967 15.4047 6.56712C13.5106 7.34456 11.7896 8.48408 10.34 9.92062C8.89037 11.3572 7.74045 13.0626 6.95591 14.9395C6.17138 16.8164 5.76758 18.8281 5.76758 20.8597V216.931C5.76758 218.963 6.17137 220.974 6.9559 222.851C7.74044 224.728 8.89035 226.434 10.34 227.87C11.7896 229.307 13.5106 230.446 15.4046 231.224C17.2987 232.001 19.3287 232.401 21.3788 232.401H98.2694C102.41 232.401 106.381 230.771 109.308 227.87C112.236 224.969 113.881 221.034 113.881 216.931V20.8597C113.881 18.8282 113.477 16.8165 112.692 14.9395C111.908 13.0626 110.758 11.3572 109.308 9.92065C107.859 8.48411 106.138 7.34458 104.244 6.56713C102.349 5.78968 100.319 5.38954 98.2694 5.38954Z"
          fill="white"
        />
        <path
          d="M59.8255 151.681C80.4765 151.681 97.2175 135.091 97.2175 114.627C97.2175 94.1625 80.4765 77.5728 59.8255 77.5728C39.1745 77.5728 22.4336 94.1625 22.4336 114.627C22.4336 135.091 39.1745 151.681 59.8255 151.681Z"
          fill="#1DAD6C"
        />
        <path
          d="M70.9043 117.159L61.6057 126.65C61.4034 126.852 61.1616 127.011 60.8951 127.117C60.6287 127.224 60.3431 127.275 60.0559 127.269H60.025C59.7372 127.275 59.4511 127.223 59.184 127.117C58.9168 127.011 58.6742 126.852 58.4707 126.65L49.1767 117.159C49.1457 117.124 49.1103 117.093 49.0792 117.062C48.7082 116.668 48.5102 116.144 48.5285 115.605C48.5468 115.067 48.7799 114.557 49.1767 114.188C49.6011 113.793 50.1617 113.573 50.7442 113.573C51.3266 113.573 51.8872 113.793 52.3117 114.188L57.8199 119.893V105.184C57.8456 104.618 58.0907 104.083 58.5044 103.691C58.918 103.299 59.4682 103.08 60.0405 103.08C60.6127 103.08 61.163 103.299 61.5766 103.691C61.9902 104.083 62.2354 104.618 62.261 105.184V119.893L67.765 114.188C68.1903 113.793 68.7515 113.573 69.3346 113.573C69.9178 113.573 70.479 113.793 70.9043 114.188C70.9354 114.219 70.9663 114.25 71.0018 114.285C71.3717 114.68 71.5691 115.203 71.5508 115.742C71.5326 116.28 71.3001 116.79 70.9043 117.159Z"
          fill="white"
        />
        <path
          d="M261.361 286.654L255.932 286.653L253.35 265.904L261.361 265.904L261.361 286.654Z"
          fill="#9F616A"
        />
        <path
          d="M262.744 291.868L245.24 291.868V291.648C245.24 289.858 245.958 288.14 247.236 286.874C248.514 285.608 250.246 284.897 252.053 284.897H252.054L262.744 284.897L262.744 291.868Z"
          fill="#2F2E41"
        />
        <path
          d="M220.119 286.476L214.875 285.087L217.789 264.38L225.529 266.431L220.119 286.476Z"
          fill="#9F616A"
        />
        <path
          d="M220.099 291.868L203.189 287.387L203.247 287.175C203.713 285.446 204.855 283.97 206.419 283.074C207.983 282.178 209.843 281.935 211.588 282.397L211.589 282.397L221.916 285.134L220.099 291.868Z"
          fill="#2F2E41"
        />
        <path
          d="M264.483 170.369L269.03 175.604L263.15 281.985H250.589L244.255 200.129L224.619 284.181L211.561 281.007L223.434 174.25L264.483 170.369Z"
          fill="#2F2E41"
        />
        <path
          d="M226.469 101.687L238.976 95.4707L258.21 95.8057L274.761 104.199L265.344 150.804L269.412 175.104H269.412C253.689 179.078 237.229 179.212 221.442 175.496L221.316 175.466C221.316 175.466 230.665 142.593 226.686 132.561L226.469 101.687Z"
          fill="#CBCBCB"
        />
        <path
          d="M262.653 76.8481C262.639 73.1283 261.137 69.566 258.475 66.9431C255.813 64.3201 252.21 62.8508 248.457 62.8577C244.703 62.8646 241.105 64.3471 238.454 66.9798C235.802 69.6125 234.313 73.1803 234.312 76.9C234.313 80.6198 235.802 84.1876 238.454 86.8203C241.105 89.453 244.703 90.9355 248.457 90.9424C252.21 90.9492 255.813 89.4799 258.475 86.857C261.137 84.234 262.639 80.6717 262.653 76.952C262.653 76.9173 262.653 76.8827 262.653 76.8481Z"
          fill="#9F616A"
        />
        <path
          d="M242.138 68.8243C242.872 68.1396 243.838 67.7532 244.846 67.7415C246.36 67.6928 248.629 68.2255 249.594 69.4452C250.37 70.4254 250.407 71.774 250.41 73.0198L250.418 76.4581C250.421 77.4753 250.433 78.5359 250.899 79.4421C251.366 80.3483 252.436 81.0366 253.414 80.7277C254.575 80.3612 255.085 78.8332 256.27 78.5584C257.161 78.352 258.075 78.9969 258.465 79.8169C258.793 80.6669 258.902 81.585 258.782 82.4874C258.669 84.2877 256.064 85.0019 255.695 86.7683C255.481 87.79 254.734 89.8282 255.695 89.401C260.123 88.9622 262.066 86.3993 264.093 83.9867L268.664 78.5453C269.186 78.005 269.587 77.3617 269.841 76.6574C269.954 76.164 269.995 75.657 269.962 75.152C269.937 73.7996 269.9 72.4474 269.85 71.0957C269.808 69.9146 269.508 68.4245 268.34 68.1818C267.733 68.0556 266.93 68.2757 266.609 67.7497C266.489 67.486 266.469 67.188 266.554 66.9111C266.812 65.5613 267.247 64.1494 266.798 62.8494C266.122 60.8889 263.794 60.0844 261.737 59.7145C259.679 59.3446 257.37 59.0263 256.079 57.3968C255.733 56.8557 255.358 56.3335 254.956 55.8325C254.363 55.2988 253.636 54.9351 252.851 54.7803C250.297 54.1398 247.599 54.3771 245.199 55.4532C244.204 55.9032 243.204 56.5092 242.113 56.4406C240.979 56.3694 240.01 55.5786 238.891 55.3862C237.083 55.0755 235.354 56.4179 234.463 58.0078C233.359 59.9778 233.257 62.6756 234.817 64.3153C235.595 65.1334 236.756 65.6953 237.079 66.773C237.211 67.2117 237.18 67.6841 237.297 68.127C237.432 68.5678 237.691 68.9617 238.042 69.2632C238.394 69.5647 238.825 69.7616 239.284 69.8311C240.39 70.0096 241.326 69.5076 242.138 68.8243Z"
          fill="#2F2E41"
        />
        <path
          d="M224.744 135.667C225.014 136.353 225.431 136.971 225.967 137.479C226.504 137.987 227.146 138.372 227.849 138.608C228.552 138.843 229.298 138.923 230.036 138.841C230.773 138.76 231.484 138.519 232.117 138.136L257.513 151.44L258.335 145.309L233.658 130.889C232.939 129.878 231.869 129.162 230.653 128.88C229.437 128.597 228.159 128.766 227.06 129.355C225.961 129.944 225.118 130.912 224.692 132.075C224.265 133.239 224.284 134.517 224.744 135.667V135.667Z"
          fill="#9F616A"
        />
        <path
          d="M258.585 136.727C258.172 137.338 257.628 137.851 256.992 138.23C256.357 138.609 255.645 138.846 254.907 138.923C254.169 139.001 253.423 138.917 252.721 138.677C252.02 138.438 251.38 138.049 250.846 137.538L223.129 145.013L223.37 137.229L250.942 130.132C251.868 129.301 253.069 128.835 254.318 128.823C255.568 128.811 256.778 129.253 257.72 130.066C258.662 130.879 259.27 132.006 259.43 133.234C259.59 134.461 259.289 135.705 258.585 136.727Z"
          fill="#9F616A"
        />
        <path
          d="M269.359 104.534L274.763 104.199C274.763 104.199 281.09 112.472 277.581 121.45C277.581 121.45 278.189 153.701 264.174 152.376C250.16 151.051 245.732 151.051 245.732 151.051L249.939 139.423L259.349 136.544C259.349 136.544 256.449 123.865 261.939 118.59L269.359 104.534Z"
          fill="#CBCBCB"
        />
        <path
          d="M230.333 104.251L229.57 100.371C229.57 100.371 218.305 100.109 216.078 117.023C216.078 117.023 205.948 142.338 215.877 145.598C225.806 148.857 236.727 145.598 236.727 145.598L235.905 134.434L224.98 132.225C224.98 132.225 230.623 124.989 227.55 118.713L230.333 104.251Z"
          fill="#CBCBCB"
        />
        <path
          d="M338.558 293H169.855C169.737 293 169.625 292.954 169.542 292.871C169.459 292.789 169.412 292.678 169.412 292.561C169.412 292.445 169.459 292.333 169.542 292.251C169.625 292.169 169.737 292.122 169.855 292.122H338.558C338.675 292.122 338.788 292.169 338.871 292.251C338.954 292.333 339.001 292.445 339.001 292.561C339.001 292.678 338.954 292.789 338.871 292.871C338.788 292.954 338.675 293 338.558 293Z"
          fill="#CBCBCB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1772_70224">
          <rect width="339" height="293" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadApp;
