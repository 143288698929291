import { updateObject } from '../../utils';
import { ActionTypes } from '../constants/action.types';
import { IFreshdeskAction, IFreshdeskState } from './types';

export const initialState: IFreshdeskState = {
  loadingFreshdesk: false,
};

export const freshdeskReducer = (
  state = initialState,
  action?: IFreshdeskAction
) => {
  switch (action?.type) {
    case ActionTypes.GET_FRESHDESK_START:
      return updateObject(state, { loadingFreshdesk: true });
    case ActionTypes.GET_FRESHDESK_SUCCESS:
      return updateObject(state, { loadingFreshdesk: false });
    case ActionTypes.GET_FRESHDESK_FAIL:
      return updateObject(state, {
        loadingFreshdesk: false,
        error: action.error,
      });
    default:
      return state;
  }
};
