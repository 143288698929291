interface MinusIconProps {
  size: number;
  fill: string;
}

const MinusIcon = ({ size, fill }: MinusIconProps) => {
  return (
    <svg
      width={size}
      height={size * 0.2}
      viewBox={`0 0 ${size} ${size * 0.2}`}
      fill="none"
    >
      <path
        d="M1.76562 0.6C1.1305 0.6 0.615625 1.11487 0.615625 1.75C0.615625 2.38513 1.1305 2.9 1.76563 2.9H17.7656C18.4008 2.9 18.9156 2.38513 18.9156 1.75C18.9156 1.11487 18.4008 0.6 17.7656 0.6H1.76562Z"
        fill={fill}
      />
    </svg>
  );
};

export default MinusIcon;
