import { FC, useEffect, useRef, useState } from 'react';
import vQ from './img/vQImage.png';
import hQ from './img/hQImage.png';
import Footer from '../../components/Footer/Footer';
import HeaderBanner from '../../components/HeaderBanner/HeaderBanner';
import classes from './GetYourQ.module.css';
import LadyAndField from '../../assets/LadyAndField.png';
import ButtonArrow from '../../assets/svg/ButtonArrow';
import { StripeMode } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { stripeRedirectAction } from '../../store/stripe/stripe.actions';
import { snipcartGetProductAction } from '../../store/snipcart/snipcart.actions';
import PreOrderFormModal from '../../components/PreOrderFormModal/PreOrderFormModal';
import { FreshdeskEmailType } from '../../utils/constants/freshdesk';

const GetYourQ: FC = () => {
  const hQPrice: string = process.env
    .REACT_APP_STRIPE_HARDWARE_Q_PRICE as string;
  const vQMonthlyPrice: string = process.env
    .REACT_APP_STRIPE_VIRTUAL_Q_MONTHLY_PRICE as string;
  const vQYearlyPrice: string = process.env
    .REACT_APP_STRIPE_VIRTUAL_Q_YEARLY_PRICE as string;
  const customerPortalUrl = process.env
    .REACT_APP_STRIPE_CUSTOMER_PORTAL_URL as string;
  const environment = process.env.REACT_APP_ENVIRONMENT as string;

  const [paymentMethod, setPaymentMethod] = useState(true);
  const [orderType, setOrderType] = useState(FreshdeskEmailType.ORDER_HQ);
  const { loadingStripe } = useAppSelector((state) => state.stripe);
  const { product, loadingSnipcart } = useAppSelector(
    (state) => state.snipcart
  );
  const stopRender = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!stopRender.current && environment !== 'prod') {
      dispatch(snipcartGetProductAction('hardware-q'));
      return () => {
        stopRender.current = true;
      };
    }
    return () => {};
  }, [dispatch]);

  const stripeClick = (mode: StripeMode) => {
    if (mode === StripeMode.Subscription) {
      const price = paymentMethod ? vQMonthlyPrice : vQYearlyPrice;
      dispatch(stripeRedirectAction(price, mode));
      return;
    }
    dispatch(stripeRedirectAction(hQPrice, mode));
  };

  const [show, setShow] = useState(false);
  const hideMessageScreen = () => {
    setShow(false);
  };

  return (
    <div className={classes.mainContainer}>
      <HeaderBanner title="Get your Q" background={LadyAndField} />
      <div className={classes.container} data-cy="virtualQ">
        <div className={classes.vQContainer}>
          <div className={classes.imageContainer}>
            <img src={vQ} alt="" />
          </div>
          <div className={classes.descriptionContainer}>
            <h2>Virtual Q</h2>
            <ul data-cy="virtualList">
              <li>Runs in a cloud.</li>
              <li>Includes 5 Galleries.</li>
              <li>Full data encryption.</li>
              <li>Up to 500GB.</li>
              <li>Unlimited invitations.</li>
              <li>Unlimited messaging.</li>
              <li>Upload photos, audio, videos, and PDFs.</li>
              <li>Accessible to the QT App.</li>
            </ul>
            <div className={classes.priceContainer}>
              <div className={classes.subscriptionsContainer}>
                <div
                  className={
                    paymentMethod
                      ? classes.switchContainer
                      : [classes.switchContainer, classes.switch].join(' ')
                  }
                >
                  <button
                    type="button"
                    className={classes.annual}
                    onClick={() => {
                      setPaymentMethod(!paymentMethod);
                    }}
                  >
                    Annual
                  </button>
                  <button
                    type="button"
                    className={classes.monthly}
                    onClick={() => {
                      setPaymentMethod(!paymentMethod);
                    }}
                  >
                    Monthly
                  </button>
                </div>
                <div className={classes.manageSubscription}>
                  <a href={customerPortalUrl}>Manage subscription</a>
                </div>
              </div>
              <div className={classes.price}>
                {paymentMethod ? '$ 9.00' : '$ 99.00'}
              </div>
              <div className={classes.buttonContainer}>
                <button
                  type="button"
                  className="buy-vq-button"
                  onClick={
                    environment === 'prod'
                      ? () => {
                          setOrderType(FreshdeskEmailType.ORDER_VQ);
                          setShow(true);
                        }
                      : () => stripeClick(StripeMode.Subscription)
                  }
                  onKeyUp={
                    environment === 'prod'
                      ? () => {
                          setOrderType(FreshdeskEmailType.ORDER_VQ);
                          setShow(true);
                        }
                      : () => stripeClick(StripeMode.Subscription)
                  }
                  disabled={loadingStripe}
                >
                  Buy now <ButtonArrow size={12} color="#FFFFFF" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className={classes.container} data-cy="hardwareQ">
        <div className={classes.vQContainer}>
          <div className={classes.imageContainer}>
            <img src={hQ} alt="" />
          </div>
          <div className={classes.descriptionContainer}>
            <h2>Hardware Q</h2>
            <ul data-cy="hardwareList">
              <li>Runs in your home on a small device.</li>
              <li>Includes 5 Galleries.</li>
              <li>Full data encryption.</li>
              <li>
                Ultimate form of privacy and security; fully under your control.
              </li>
              <li>Unlimited invitations.</li>
              <li>Unlimited messaging.</li>
              <li>Upload photos, audio, videos, and PDFs.</li>
              <li>Accessible to the QT App.</li>
            </ul>
            <div className={classes.priceContainer}>
              <div className={classes.buttonContainer}>
                {environment !== 'prod' ? (
                  <button
                    type="button"
                    disabled={loadingSnipcart || loadingStripe}
                    className="snipcart-add-item"
                    data-item-id={
                      product && product.stock > 0
                        ? 'hardware-q'
                        : 'pre-order-hardware-q'
                    }
                    data-item-max-quantity={
                      product && product.stock > 0 ? product?.stock : 100
                    }
                    data-item-price="299.99"
                    data-item-url="/products.json"
                    data-item-description={
                      product && product.stock > 0
                        ? 'Ultimate form of privacy and security; fully under your control.'
                        : 'PRE ORDER NOW the ultimate form of privacy and security'
                    }
                    data-item-image={hQ}
                    data-item-name={
                      product && product.stock > 0
                        ? 'Hardware Q'
                        : 'Pre Order Hardware Q'
                    }
                  >
                    {product && product.stock > 0
                      ? 'Continue to checkout'
                      : 'PRE ORDER NOW'}{' '}
                    <ButtonArrow size={12} color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setOrderType(FreshdeskEmailType.ORDER_HQ);
                      setShow(true);
                    }}
                    className="buy-now-button"
                  >
                    Buy now <ButtonArrow size={12} color="#FFFFFF" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {show && (
        <PreOrderFormModal
          hideMessageScreen={hideMessageScreen}
          type={orderType}
        />
      )}
    </div>
  );
};

export default GetYourQ;
