const GradientDots = () => {
  return (
    <svg viewBox="0 0 1399 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_440_59925)">
        <rect width="14" height="14" transform="translate(0 -4)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(322 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 331 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(336 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 345 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(350 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 359 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(364 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 373 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(378 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 387 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(392 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 401 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(406 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 415 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(420 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 429 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(434 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 443 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(448 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 457 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(462 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 471 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(476 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 485 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(490 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 499 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(504 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 513 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(518 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 527 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(532 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 541 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(546 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 555 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(560 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 569 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(574 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 583 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(588 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 597 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(602 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 611 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(616 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 625 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(630 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 639 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(644 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 653 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(658 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 667 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(672 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 681 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(686 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 695 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(700 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 709 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(714 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 723 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(728 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 737 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(742 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 751 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(756 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 765 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(770 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 779 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(784 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 793 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(798 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 807 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(812 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 821 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(826 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 835 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(840 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 849 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(854 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 863 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(868 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 877 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(882 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 891 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(896 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 905 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(910 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 919 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(924 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 933 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(938 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 947 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(952 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 961 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(966 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 975 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(980 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 989 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(994 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1003 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1008 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1017 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1022 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1031 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1036 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1045 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1050 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1059 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1064 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1073 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1078 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1087 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1092 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1101 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1106 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1115 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1120 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1129 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1134 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1143 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1148 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1157 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1162 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1171 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1176 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1185 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1190 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1199 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1204 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1213 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1218 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1227 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1232 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1241 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1246 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1255 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1260 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1269 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1274 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1283 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1288 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1297 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1302 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1311 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1316 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1325 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1330 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1339 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1344 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1353 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1358 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1367 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1372 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1381 1)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1386 -4)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1395 1)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 10)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(322 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 331 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(336 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 345 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(350 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 359 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(364 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 373 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(378 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 387 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(392 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 401 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(406 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 415 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(420 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 429 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(434 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 443 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(448 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 457 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(462 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 471 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(476 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 485 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(490 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 499 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(504 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 513 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(518 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 527 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(532 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 541 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(546 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 555 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(560 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 569 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(574 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 583 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(588 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 597 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(602 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 611 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(616 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 625 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(630 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 639 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(644 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 653 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(658 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 667 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(672 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 681 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(686 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 695 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(700 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 709 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(714 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 723 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(728 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 737 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(742 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 751 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(756 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 765 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(770 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 779 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(784 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 793 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(798 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 807 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(812 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 821 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(826 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 835 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(840 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 849 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(854 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 863 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(868 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 877 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(882 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 891 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(896 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 905 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(910 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 919 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(924 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 933 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(938 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 947 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(952 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 961 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(966 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 975 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(980 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 989 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(994 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1003 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1008 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1017 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1022 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1031 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1036 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1045 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1050 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1059 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1064 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1073 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1078 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1087 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1092 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1101 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1106 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1115 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1120 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1129 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1134 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1143 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1148 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1157 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1162 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1171 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1176 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1185 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1190 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1199 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1204 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1213 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1218 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1227 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1232 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1241 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1246 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1255 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1260 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1269 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1274 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1283 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1288 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1297 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1302 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1311 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1316 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1325 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1330 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1339 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1344 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1353 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1358 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1367 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1372 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1381 15)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1386 10)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1395 15)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 24)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(322 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 331 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(336 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 345 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(350 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 359 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(364 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 373 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(378 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 387 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(392 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 401 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(406 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 415 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(420 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 429 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(434 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 443 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(448 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 457 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(462 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 471 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(476 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 485 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(490 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 499 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(504 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 513 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(518 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 527 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(532 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 541 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(546 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 555 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(560 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 569 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(574 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 583 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(588 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 597 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(602 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 611 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(616 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 625 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(630 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 639 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(644 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 653 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(658 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 667 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(672 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 681 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(686 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 695 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(700 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 709 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(714 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 723 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(728 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 737 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(742 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 751 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(756 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 765 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(770 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 779 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(784 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 793 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(798 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 807 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(812 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 821 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(826 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 835 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(840 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 849 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(854 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 863 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(868 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 877 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(882 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 891 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(896 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 905 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(910 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 919 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(924 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 933 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(938 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 947 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(952 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 961 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(966 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 975 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(980 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 989 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(994 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1003 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1008 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1017 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1022 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1031 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1036 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1045 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1050 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1059 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1064 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1073 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1078 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1087 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1092 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1101 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1106 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1115 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1120 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1129 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1134 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1143 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1148 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1157 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1162 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1171 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1176 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1185 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1190 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1199 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1204 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1213 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1218 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1227 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1232 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1241 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1246 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1255 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1260 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1269 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1274 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1283 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1288 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1297 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1302 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1311 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1316 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1325 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1330 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1339 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1344 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1353 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1358 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1367 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1372 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1381 29)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1386 24)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1395 29)"
          fill="#2FCF87"
        />
        <rect width="14" height="14" transform="translate(0 38)" fill="white" />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 9 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(14 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 23 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(28 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 37 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(42 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 51 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(56 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 65 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(70 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 79 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(84 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 93 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(98 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 107 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(112 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 121 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(126 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 135 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(140 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 149 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(154 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 163 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(168 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 177 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(182 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 191 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(196 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 205 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(210 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 219 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(224 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 233 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(238 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 247 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(252 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 261 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(266 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 275 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(280 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 289 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(294 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 303 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(308 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 317 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(322 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 331 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(336 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 345 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(350 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 359 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(364 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 373 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(378 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 387 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(392 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 401 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(406 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 415 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(420 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 429 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(434 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 443 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(448 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 457 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(462 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 471 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(476 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 485 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(490 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 499 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(504 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 513 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(518 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 527 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(532 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 541 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(546 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 555 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(560 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 569 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(574 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 583 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(588 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 597 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(602 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 611 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(616 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 625 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(630 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 639 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(644 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 653 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(658 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 667 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(672 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 681 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(686 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 695 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(700 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 709 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(714 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 723 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(728 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 737 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(742 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 751 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(756 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 765 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(770 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 779 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(784 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 793 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(798 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 807 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(812 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 821 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(826 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 835 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(840 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 849 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(854 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 863 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(868 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 877 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(882 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 891 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(896 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 905 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(910 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 919 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(924 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 933 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(938 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 947 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(952 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 961 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(966 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 975 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(980 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 989 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(994 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1003 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1008 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1017 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1022 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1031 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1036 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1045 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1050 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1059 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1064 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1073 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1078 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1087 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1092 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1101 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1106 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1115 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1120 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1129 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1134 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1143 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1148 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1157 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1162 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1171 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1176 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1185 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1190 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1199 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1204 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1213 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1218 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1227 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1232 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1241 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1246 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1255 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1260 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1269 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1274 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1283 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1288 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1297 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1302 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1311 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1316 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1325 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1330 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1339 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1344 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1353 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1358 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1367 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1372 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1381 43)"
          fill="#2FCF87"
        />
        <rect
          width="14"
          height="14"
          transform="translate(1386 38)"
          fill="white"
        />
        <rect
          width="4"
          height="4"
          rx="1"
          transform="matrix(-1 0 0 1 1395 43)"
          fill="#2FCF87"
        />
        <rect
          y="-6"
          width="1399"
          height="56"
          fill="url(#paint0_linear_440_59925)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_440_59925"
          x1="699.5"
          y1="50"
          x2="699.5"
          y2="-6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="92%" />
          <stop offset="100%" stopColor="white" stopOpacity="25%" />
        </linearGradient>
        <clipPath id="clip0_440_59925">
          <rect width="1399" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GradientDots;
