import { Navigate, NavLink, useSearchParams } from 'react-router-dom';
import classes from './SuccessCheckout.module.css';
import SuccessImage from '../../assets/svg/SuccessImage';
import Button from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';

const SuccessCheckout = () => {
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');
  if (success === '') {
    return <Navigate replace to="/" />;
  }
  return (
    <div className={classes.container}>
      <div className={classes.messageContainer}>
        <h1>Your purchase has been completed succesfully!</h1>
        <p>
          In approximately 15 minutes, you will receive your receipt and the
          information to claim your vQ. If you have any additional questions,
          please contact support.
        </p>
        <div className={classes.imageContainer}>
          <SuccessImage />
        </div>
        <NavLink to="/" end>
          <Button value="Back to home" />
        </NavLink>
      </div>
      <Footer />
    </div>
  );
};

export default SuccessCheckout;
