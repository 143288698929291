export enum ActionTypes {
  GET_FRESHDESK_START = 'GET_FRESHDESK_START',
  GET_FRESHDESK_SUCCESS = 'GET_FRESHDESK_SUCCESS',
  GET_FRESHDESK_FAIL = 'GET_FRESHDESK_FAIL',

  GET_STRIPE_START = 'GET_STRIPE_START',
  GET_STRIPE_SUCCESS = 'GET_STRIPE_SUCCESS',
  GET_STRIPE_FAIL = 'GET_STRIPE_FAIL',

  GET_SNIPCART_START = 'GET_SNIPCART_START',
  GET_SNIPCART_SUCCESS = 'GET_SNIPCART_SUCCESS',
  GET_SNIPCART_FAIL = 'GET_SNIPCART_FAIL',
}
