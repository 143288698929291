import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { snipcartReducer } from './snipcart/snipcart.reducer';
import { stripeReducer } from './stripe/stripe.reducer';
import { freshdeskReducer } from './freshdesk/freshdesk.reducer';

export const store = configureStore({
  reducer: {
    freshdesk: freshdeskReducer,
    stripe: stripeReducer,
    snipcart: snipcartReducer,
  },
  middleware(gDM) {
    return gDM().concat(thunk);
  },
  // Depending on the environment, we could also use process.env.NODE_ENV === 'development'
  devTools: process.env.NODE_ENV !== 'production',
});
