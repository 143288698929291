import { loadStripe } from '@stripe/stripe-js';
import { StripeMode } from '../utils/constants';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''
);

export class StripeService {
  async redirectToStripe(price: string, mode: StripeMode): Promise<void> {
    const stripe = await stripePromise;
    if (stripe) {
      await stripe.redirectToCheckout({
        lineItems: [
          {
            price,
            quantity: 1,
          },
        ],
        mode,
        successUrl: `${window.location.origin}/success-checkout?success=stripe`,
        cancelUrl: `${window.location.origin}/get-your-q`,
      });
    }
  }
}
