import React from 'react';

interface AppStoreBadgeEnProps {
  size: number;
}

const AppStoreBadgeEn = ({ size = 36 }: AppStoreBadgeEnProps) => {
  return (
    <svg
      width={size / 0.3}
      height={size}
      viewBox="0 0 60 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_612_64870)">
        <path
          d="M57.8653 18H2.10178C0.943111 18 0 17.0424 0 15.8701V2.1267C0 0.954 0.943111 0 2.10178 0H57.8653C59.0236 0 60 0.954 60 2.1267V15.8701C60 17.0424 59.0236 18 57.8653 18Z"
          fill="white"
        />
        <path
          d="M59.5698 15.8705C59.5698 16.8227 58.808 17.594 57.8658 17.594H2.10178C1.16 17.594 0.395554 16.8227 0.395554 15.8705V2.12614C0.395554 1.17439 1.16 0.400391 2.10178 0.400391H57.8653C58.808 0.400391 59.5693 1.17439 59.5693 2.12614L59.5698 15.8705Z"
          fill="black"
        />
        <path
          d="M13.3902 8.90279C13.3773 7.45244 14.5631 6.74684 14.6173 6.71399C13.9458 5.72264 12.9049 5.58719 12.5391 5.57639C11.6649 5.48324 10.8169 6.10604 10.3716 6.10604C9.91733 6.10604 9.23155 5.58539 8.49244 5.60069C7.54133 5.61554 6.65155 6.17309 6.16355 7.03889C5.15644 8.80424 5.90755 11.3985 6.87244 12.8254C7.35511 13.5243 7.91911 14.3046 8.65733 14.2771C9.37955 14.247 9.64933 13.8109 10.5209 13.8109C11.3844 13.8109 11.6378 14.2771 12.3907 14.2596C13.1658 14.247 13.6538 13.5576 14.1196 12.8524C14.6773 12.0514 14.9013 11.2626 14.9102 11.2221C14.892 11.2158 13.4049 10.6411 13.3902 8.90279Z"
          fill="white"
        />
        <path
          d="M11.968 4.6379C12.3564 4.14605 12.6222 3.4769 12.5484 2.79785C11.9862 2.82305 11.2831 3.1916 10.8782 3.67265C10.52 4.09655 10.2 4.79135 10.2827 5.44475C10.9142 5.49245 11.5627 5.1221 11.968 4.6379Z"
          fill="white"
        />
        <path
          d="M23.8422 14.1771H22.8329L22.28 12.418H20.3582L19.8315 14.1771H18.8489L20.7529 8.18848H21.9289L23.8422 14.1771ZM22.1133 11.68L21.6133 10.1163C21.5604 9.95653 21.4613 9.58033 21.3151 8.98813H21.2973C21.2391 9.24283 21.1453 9.61903 21.0164 10.1163L20.5253 11.68H22.1133Z"
          fill="white"
        />
        <path
          d="M28.7387 11.9647C28.7387 12.6991 28.5427 13.2796 28.1507 13.7057C27.7995 14.0851 27.3635 14.2745 26.8431 14.2745C26.2813 14.2745 25.8778 14.0702 25.632 13.6616H25.6142V15.9364H24.6667V11.2802C24.6667 10.8185 24.6547 10.3447 24.6315 9.85867H25.4649L25.5178 10.5431H25.5355C25.8515 10.0274 26.3311 9.77002 26.9747 9.77002C27.4778 9.77002 27.8978 9.97117 28.2338 10.3739C28.5707 10.7771 28.7387 11.3072 28.7387 11.9647ZM27.7733 11.9998C27.7733 11.5795 27.68 11.233 27.4924 10.9603C27.2875 10.6759 27.0124 10.5337 26.6675 10.5337C26.4338 10.5337 26.2213 10.6129 26.0315 10.769C25.8413 10.9265 25.7169 11.1322 25.6587 11.3869C25.6293 11.5057 25.6147 11.6029 25.6147 11.6794V12.3994C25.6147 12.7135 25.7098 12.9785 25.9 13.195C26.0902 13.4114 26.3373 13.5194 26.6413 13.5194C26.9982 13.5194 27.276 13.3799 27.4747 13.1018C27.6738 12.8233 27.7733 12.4561 27.7733 11.9998Z"
          fill="white"
        />
        <path
          d="M33.644 11.9647C33.644 12.6991 33.448 13.2796 33.0555 13.7057C32.7049 14.0851 32.2689 14.2745 31.7484 14.2745C31.1867 14.2745 30.7831 14.0702 30.5378 13.6616H30.52V15.9364H29.5724V11.2802C29.5724 10.8185 29.5604 10.3447 29.5373 9.85867H30.3707L30.4235 10.5431H30.4413C30.7569 10.0274 31.2364 9.77002 31.8804 9.77002C32.3831 9.77002 32.8031 9.97117 33.14 10.3739C33.4755 10.7771 33.644 11.3072 33.644 11.9647ZM32.6787 11.9998C32.6787 11.5795 32.5849 11.233 32.3973 10.9603C32.1924 10.6759 31.9182 10.5337 31.5729 10.5337C31.3387 10.5337 31.1267 10.6129 30.9364 10.769C30.7462 10.9265 30.6222 11.1322 30.564 11.3869C30.5351 11.5057 30.52 11.6029 30.52 11.6794V12.3994C30.52 12.7135 30.6151 12.9785 30.8044 13.195C30.9947 13.411 31.2418 13.5194 31.5467 13.5194C31.9035 13.5194 32.1813 13.3799 32.38 13.1018C32.5791 12.8233 32.6787 12.4561 32.6787 11.9998Z"
          fill="white"
        />
        <path
          d="M39.1284 12.4975C39.1284 13.0069 38.9538 13.4214 38.6031 13.7413C38.2178 14.091 37.6813 14.2656 36.992 14.2656C36.3556 14.2656 35.8453 14.1414 35.4591 13.8925L35.6787 13.0929C36.0947 13.3476 36.5511 13.4754 37.0484 13.4754C37.4053 13.4754 37.6831 13.3935 37.8827 13.2306C38.0813 13.0677 38.1804 12.849 38.1804 12.5763C38.1804 12.3333 38.0987 12.1285 37.9347 11.9625C37.7716 11.7964 37.4991 11.6421 37.1187 11.4994C36.0831 11.1084 35.5658 10.5355 35.5658 9.78224C35.5658 9.28994 35.7471 8.88629 36.1102 8.57219C36.472 8.25764 36.9547 8.10059 37.5582 8.10059C38.0964 8.10059 38.5436 8.19554 38.9004 8.38499L38.6635 9.16709C38.3302 8.98349 37.9533 8.89169 37.5316 8.89169C37.1982 8.89169 36.9378 8.97494 36.7511 9.14054C36.5933 9.28859 36.5142 9.46904 36.5142 9.68279C36.5142 9.91949 36.6044 10.1152 36.7858 10.2691C36.9436 10.4113 37.2302 10.5652 37.6462 10.7313C38.1551 10.9387 38.5289 11.1813 38.7693 11.4594C39.0089 11.7366 39.1284 12.0835 39.1284 12.4975Z"
          fill="white"
        />
        <path
          d="M42.2613 10.5787H41.2169V12.6752C41.2169 13.2085 41.4009 13.4749 41.7698 13.4749C41.9391 13.4749 42.0795 13.46 42.1907 13.4303L42.2169 14.1589C42.0302 14.2295 41.7844 14.2651 41.48 14.2651C41.1058 14.2651 40.8133 14.1494 40.6022 13.9186C40.392 13.6873 40.2862 13.2994 40.2862 12.7544V10.5778H39.664V9.85776H40.2862V9.06711L41.2169 8.78271V9.85776H42.2613V10.5787Z"
          fill="white"
        />
        <path
          d="M46.9738 11.9823C46.9738 12.646 46.7862 13.191 46.412 13.6171C46.0195 14.0559 45.4987 14.2746 44.8493 14.2746C44.2235 14.2746 43.7253 14.0644 43.3538 13.6441C42.9822 13.2238 42.7964 12.6933 42.7964 12.0538C42.7964 11.3847 42.9875 10.8366 43.3711 10.4104C43.7538 9.98381 44.2702 9.77051 44.9195 9.77051C45.5453 9.77051 46.0489 9.98066 46.4289 10.4014C46.7924 10.8096 46.9738 11.3365 46.9738 11.9823ZM45.9907 12.0133C45.9907 11.6151 45.9067 11.2735 45.7364 10.9887C45.5378 10.644 45.2538 10.4721 44.8858 10.4721C44.5049 10.4721 44.2155 10.6444 44.0169 10.9887C43.8467 11.274 43.7627 11.6209 43.7627 12.0313C43.7627 12.4296 43.8467 12.7711 44.0169 13.0555C44.2218 13.4002 44.508 13.5721 44.8773 13.5721C45.2391 13.5721 45.5231 13.3966 45.728 13.0465C45.9027 12.7563 45.9907 12.4111 45.9907 12.0133Z"
          fill="white"
        />
        <path
          d="M50.0538 10.7024C49.96 10.6849 49.86 10.6759 49.7551 10.6759C49.4218 10.6759 49.164 10.8032 48.9827 11.0584C48.8249 11.2834 48.7458 11.5678 48.7458 11.9111V14.1769H47.7987L47.8075 11.2186C47.8075 10.7209 47.7955 10.2677 47.772 9.85912H48.5973L48.632 10.6853H48.6582C48.7582 10.4014 48.916 10.1728 49.132 10.0013C49.3431 9.84697 49.5711 9.77002 49.8169 9.77002C49.9044 9.77002 49.9835 9.77632 50.0538 9.78757V10.7024Z"
          fill="white"
        />
        <path
          d="M54.2915 11.8138C54.2915 11.9857 54.2804 12.1306 54.2569 12.2489H51.4142C51.4253 12.6755 51.5627 13.0018 51.8267 13.2268C52.0662 13.4279 52.376 13.5287 52.7564 13.5287C53.1773 13.5287 53.5613 13.4608 53.9067 13.3244L54.0551 13.9904C53.6516 14.1686 53.1751 14.2573 52.6253 14.2573C51.964 14.2573 51.4449 14.0602 51.0671 13.6664C50.6902 13.2727 50.5013 12.7439 50.5013 12.0806C50.5013 11.4295 50.6769 10.8872 51.0284 10.4548C51.3964 9.99306 51.8938 9.76221 52.5195 9.76221C53.1342 9.76221 53.5995 9.99306 53.9155 10.4548C54.1658 10.8215 54.2915 11.2751 54.2915 11.8138ZM53.388 11.5649C53.3942 11.2805 53.3324 11.0348 53.204 10.8274C53.04 10.5605 52.788 10.4273 52.4489 10.4273C52.1391 10.4273 51.8871 10.5574 51.6947 10.8184C51.5369 11.0258 51.4431 11.2747 51.4142 11.5645H53.388V11.5649Z"
          fill="white"
        />
        <path
          d="M21.8 4.50392C21.8 5.03357 21.6431 5.43227 21.3298 5.70002C21.0396 5.94707 20.6271 6.07082 20.0929 6.07082C19.828 6.07082 19.6013 6.05912 19.4116 6.03572V3.14177C19.6591 3.10127 19.9258 3.08057 20.2138 3.08057C20.7227 3.08057 21.1062 3.19262 21.3649 3.41672C21.6547 3.67007 21.8 4.03232 21.8 4.50392ZM21.3089 4.51697C21.3089 4.17362 21.2191 3.91037 21.0396 3.72677C20.86 3.54362 20.5978 3.45182 20.2524 3.45182C20.1058 3.45182 19.9809 3.46172 19.8773 3.48242V5.68247C19.9347 5.69147 20.0396 5.69552 20.192 5.69552C20.5484 5.69552 20.8236 5.59517 21.0173 5.39447C21.2111 5.19377 21.3089 4.90127 21.3089 4.51697Z"
          fill="white"
        />
        <path
          d="M24.404 4.96667C24.404 5.29292 24.312 5.56022 24.128 5.76992C23.9351 5.98547 23.6796 6.09302 23.3604 6.09302C23.0529 6.09302 22.808 5.98997 22.6253 5.78297C22.4431 5.57642 22.352 5.31587 22.352 5.00177C22.352 4.67327 22.4458 4.40372 22.6342 4.19447C22.8227 3.98522 23.076 3.88037 23.3951 3.88037C23.7027 3.88037 23.9498 3.98342 24.1369 4.18997C24.3147 4.39067 24.404 4.64987 24.404 4.96667ZM23.9209 4.98197C23.9209 4.78622 23.8791 4.61837 23.796 4.47842C23.6982 4.30922 23.5591 4.22462 23.3782 4.22462C23.1911 4.22462 23.0489 4.30922 22.9511 4.47842C22.8676 4.61837 22.8262 4.78892 22.8262 4.99052C22.8262 5.18627 22.868 5.35412 22.9511 5.49407C23.052 5.66327 23.1924 5.74787 23.3738 5.74787C23.5516 5.74787 23.6911 5.66192 23.7916 5.48957C23.8778 5.34692 23.9209 5.17772 23.9209 4.98197Z"
          fill="white"
        />
        <path
          d="M27.8956 3.92334L27.24 6.04464H26.8133L26.5418 5.12349C26.4729 4.89354 26.4169 4.66494 26.3733 4.43814H26.3649C26.3244 4.67124 26.2684 4.89939 26.1964 5.12349L25.908 6.04464H25.4764L24.86 3.92334H25.3387L25.5756 4.93179C25.6329 5.17029 25.68 5.39754 25.7178 5.61264H25.7262C25.7609 5.43534 25.8182 5.20944 25.8991 4.93629L26.1964 3.92379H26.576L26.8609 4.91469C26.9298 5.15634 26.9858 5.38899 27.0289 5.61309H27.0418C27.0733 5.39484 27.1209 5.16219 27.184 4.91469L27.4382 3.92379H27.8956V3.92334Z"
          fill="white"
        />
        <path
          d="M30.3102 6.04476H29.8445V4.82976C29.8445 4.45536 29.704 4.26816 29.4222 4.26816C29.284 4.26816 29.1725 4.31946 29.0858 4.42251C29 4.52556 28.9565 4.64706 28.9565 4.78611V6.04431H28.4907V4.52961C28.4907 4.34331 28.4849 4.14126 28.4738 3.92256H28.8831L28.9049 4.25421H28.9178C28.972 4.15116 29.0529 4.06611 29.1591 3.99816C29.2853 3.91896 29.4267 3.87891 29.5813 3.87891C29.7769 3.87891 29.9396 3.94281 30.0689 4.07106C30.2298 4.22811 30.3102 4.46256 30.3102 4.77396V6.04476Z"
          fill="white"
        />
        <path
          d="M31.5947 6.04484H31.1293V2.9502H31.5947V6.04484Z"
          fill="white"
        />
        <path
          d="M34.3369 4.96667C34.3369 5.29292 34.2449 5.56022 34.0609 5.76992C33.868 5.98547 33.612 6.09302 33.2933 6.09302C32.9853 6.09302 32.7405 5.98997 32.5582 5.78297C32.376 5.57642 32.2849 5.31587 32.2849 5.00177C32.2849 4.67327 32.3787 4.40372 32.5671 4.19447C32.7556 3.98522 33.0089 3.88037 33.3276 3.88037C33.6356 3.88037 33.8822 3.98342 34.0698 4.18997C34.2476 4.39067 34.3369 4.64987 34.3369 4.96667ZM33.8533 4.98197C33.8533 4.78622 33.8116 4.61837 33.7285 4.47842C33.6311 4.30922 33.4916 4.22462 33.3111 4.22462C33.1236 4.22462 32.9813 4.30922 32.884 4.47842C32.8005 4.61837 32.7591 4.78892 32.7591 4.99052C32.7591 5.18627 32.8009 5.35412 32.884 5.49407C32.9849 5.66327 33.1253 5.74787 33.3067 5.74787C33.4845 5.74787 33.6236 5.66192 33.724 5.48957C33.8107 5.34692 33.8533 5.17772 33.8533 4.98197Z"
          fill="white"
        />
        <path
          d="M36.5911 6.04487H36.1729L36.1382 5.80052H36.1253C35.9822 5.99537 35.7782 6.09302 35.5133 6.09302C35.3156 6.09302 35.1556 6.02867 35.0351 5.90087C34.9258 5.78477 34.8711 5.64032 34.8711 5.46887C34.8711 5.20967 34.9778 5.01212 35.1924 4.87532C35.4067 4.73852 35.708 4.67147 36.096 4.67462V4.63502C36.096 4.35557 35.9511 4.21607 35.6609 4.21607C35.4542 4.21607 35.272 4.26872 35.1147 4.37312L35.02 4.06352C35.2147 3.94157 35.4551 3.88037 35.7387 3.88037C36.2862 3.88037 36.5609 4.17287 36.5609 4.75787V5.53907C36.5609 5.75102 36.5711 5.91977 36.5911 6.04487ZM36.1076 5.31587V4.98872C35.5938 4.97972 35.3369 5.12237 35.3369 5.41622C35.3369 5.52692 35.3662 5.60972 35.4262 5.66507C35.4862 5.72042 35.5627 5.74787 35.6538 5.74787C35.756 5.74787 35.8516 5.71502 35.9387 5.64977C36.0262 5.58407 36.08 5.50082 36.1 5.39867C36.1049 5.37572 36.1076 5.34782 36.1076 5.31587Z"
          fill="white"
        />
        <path
          d="M39.2378 6.04484H38.8244L38.8027 5.7042H38.7898C38.6578 5.96339 38.4329 6.093 38.1169 6.093C37.8644 6.093 37.6542 5.99265 37.4876 5.79195C37.3209 5.59125 37.2378 5.3307 37.2378 5.01075C37.2378 4.6674 37.328 4.38929 37.5093 4.1769C37.6849 3.97889 37.9 3.8799 38.156 3.8799C38.4373 3.8799 38.6342 3.97575 38.7462 4.1679H38.7551V2.9502H39.2213V5.47335C39.2213 5.6799 39.2267 5.87024 39.2378 6.04484ZM38.7551 5.15024V4.79655C38.7551 4.73535 38.7507 4.68585 38.7422 4.64805C38.716 4.53465 38.6596 4.43925 38.5738 4.3623C38.4871 4.28535 38.3827 4.24665 38.2622 4.24665C38.0884 4.24665 37.9524 4.3164 37.8524 4.45635C37.7533 4.5963 37.7031 4.77495 37.7031 4.9932C37.7031 5.20289 37.7507 5.373 37.8462 5.50395C37.9471 5.64345 38.0831 5.7132 38.2533 5.7132C38.4062 5.7132 38.5284 5.65514 38.6213 5.53859C38.7111 5.43105 38.7551 5.30145 38.7551 5.15024Z"
          fill="white"
        />
        <path
          d="M43.2213 4.96667C43.2213 5.29292 43.1293 5.56022 42.9453 5.76992C42.7525 5.98547 42.4973 6.09302 42.1778 6.09302C41.8707 6.09302 41.6258 5.98997 41.4427 5.78297C41.2605 5.57642 41.1693 5.31587 41.1693 5.00177C41.1693 4.67327 41.2631 4.40372 41.4516 4.19447C41.64 3.98522 41.8933 3.88037 42.2129 3.88037C42.52 3.88037 42.7676 3.98342 42.9542 4.18997C43.132 4.39067 43.2213 4.64987 43.2213 4.96667ZM42.7387 4.98197C42.7387 4.78622 42.6969 4.61837 42.6138 4.47842C42.5156 4.30922 42.3769 4.22462 42.1956 4.22462C42.0089 4.22462 41.8667 4.30922 41.7685 4.47842C41.6849 4.61837 41.6436 4.78892 41.6436 4.99052C41.6436 5.18627 41.6853 5.35412 41.7685 5.49407C41.8693 5.66327 42.0098 5.74787 42.1911 5.74787C42.3689 5.74787 42.5089 5.66192 42.6093 5.48957C42.6951 5.34692 42.7387 5.17772 42.7387 4.98197Z"
          fill="white"
        />
        <path
          d="M45.7258 6.04476H45.2604V4.82976C45.2604 4.45536 45.12 4.26816 44.8378 4.26816C44.6995 4.26816 44.588 4.31946 44.5018 4.42251C44.4155 4.52556 44.3724 4.64706 44.3724 4.78611V6.04431H43.9062V4.52961C43.9062 4.34331 43.9009 4.14126 43.8898 3.92256H44.2987L44.3204 4.25421H44.3333C44.388 4.15116 44.4689 4.06611 44.5747 3.99816C44.7013 3.91896 44.8422 3.87891 44.9973 3.87891C45.1924 3.87891 45.3551 3.94281 45.4844 4.07106C45.6458 4.22811 45.7258 4.46256 45.7258 4.77396V6.04476Z"
          fill="white"
        />
        <path
          d="M48.8604 4.27657H48.3476V5.30707C48.3476 5.56897 48.4387 5.69992 48.6191 5.69992C48.7027 5.69992 48.772 5.69272 48.8267 5.67787L48.8387 6.03562C48.7467 6.07072 48.6258 6.08827 48.4769 6.08827C48.2929 6.08827 48.1498 6.03157 48.0462 5.91817C47.9422 5.80477 47.8907 5.61397 47.8907 5.34622V4.27657H47.5844V3.92332H47.8907V3.53452L48.3471 3.39502V3.92287H48.86V4.27657H48.8604Z"
          fill="white"
        />
        <path
          d="M51.3262 6.04484H50.86V4.83885C50.86 4.4586 50.7196 4.26824 50.4382 4.26824C50.2222 4.26824 50.0747 4.3785 49.9938 4.599C49.98 4.64535 49.972 4.70205 49.972 4.76865V6.0444H49.5067V2.9502H49.972V4.22865H49.9809C50.1276 3.996 50.3378 3.8799 50.6102 3.8799C50.8031 3.8799 50.9627 3.9438 51.0893 4.07205C51.2471 4.2318 51.3262 4.4694 51.3262 4.7835V6.04484Z"
          fill="white"
        />
        <path
          d="M53.8698 4.88401C53.8698 4.96861 53.8636 5.03971 53.8524 5.09776H52.4556C52.4618 5.30746 52.5284 5.46721 52.6578 5.57791C52.776 5.67691 52.9284 5.72641 53.1151 5.72641C53.3218 5.72641 53.5102 5.69311 53.68 5.62606L53.7529 5.95366C53.5542 6.04096 53.3204 6.08461 53.0498 6.08461C52.7253 6.08461 52.4698 5.98786 52.2849 5.79436C52.0991 5.60086 52.0071 5.34121 52.0071 5.01541C52.0071 4.69546 52.0929 4.42906 52.2658 4.21666C52.4462 3.98986 52.6902 3.87646 52.9982 3.87646C53.2996 3.87646 53.5284 3.98986 53.6831 4.21666C53.808 4.39666 53.8698 4.61941 53.8698 4.88401ZM53.4253 4.76206C53.4289 4.62211 53.3982 4.50151 53.3351 4.39981C53.2542 4.26886 53.1311 4.20316 52.9644 4.20316C52.8124 4.20316 52.6884 4.26706 52.5938 4.39531C52.5164 4.49746 52.4707 4.61941 52.4556 4.76206H53.4253Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_612_64870">
          <rect width="60" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppStoreBadgeEn;
