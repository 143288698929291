import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import classes from './OurProducts.module.css';
import LadyAndField from '../../assets/LadyAndField.png';
import { ourProducts } from '../../utils/constants';
import QAndQTSide from '../../assets/QAndQTSide.png';
import DataStructure from '../../assets/DataStructure.png';
import Phone from '../../assets/Phone.png';
import QIsometric from '../../assets/QIsometric.png';
import Galleries from '../../assets/svg/Galleries';
import Invitation from '../../assets/svg/Invitation';
import Messaging from '../../assets/svg/Messaging';
import GradientDotsMobile from '../../assets/svg/GradientDotsMobile';
import AppStoreBadgeEn from '../../assets/svg/AppStoreBadgeEn';
import PlayStoreBadgeEn from '../../assets/svg/PlayStoreBadgeEn';
import GradientDots from '../../assets/svg/GradientDots';
import QRCode from '../../assets/svg/QRCode';

const OurProducts: FC = () => {
  const appStore = process.env.REACT_APP_QUOTIENT_APP_STORE as string;
  const playStore = process.env.REACT_APP_QUOTIENT_PLAY_STORE as string;
  const ref = useRef<null | HTMLDivElement>(null);

  const handleClick = () => {
    if (ref.current && !Number.isNaN(ref.current.offsetTop)) {
      const offset = ref.current.offsetTop - 90;
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  };
  return (
    <div className={classes.mainContainer}>
      <div
        className={classes.header}
        style={{ backgroundImage: `url(${LadyAndField})` }}
      >
        <div className={classes.headerContent} data-cy="heroSection">
          <h1 className={classes.titleHeader}>Our Products</h1>
          <button type="button" onClick={handleClick} onKeyUp={handleClick}>
            Get Now
          </button>
        </div>
      </div>
      <div className={classes.dotsMobile}>
        <GradientDotsMobile />
      </div>
      <div className={classes.dots}>
        <GradientDots />
      </div>
      <div className={classes.container}>
        <div className={classes.sectionContainer}>
          <div className={classes.title}>
            <h2>{ourProducts[0].title}</h2>
          </div>
          <div className={classes.content}>
            <p>{ourProducts[0].content}</p>
          </div>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.title}>
            <h3 className={classes.theQ}>{ourProducts[1].title}</h3>
          </div>
          <div className={classes.content}>
            <p>{ourProducts[1].content}</p>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <img src={DataStructure} loading="lazy" alt="Data Structure" />
            </div>
            <div className={classes.col}>
              <img src={QAndQTSide} loading="lazy" alt="Q and QT" />
            </div>
          </div>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.title}>
            <h2>{ourProducts[2].title}</h2>
          </div>
          <div className={classes.content}>
            <p>{ourProducts[2].content}</p>
          </div>
          <div className={classes.rowQTApp}>
            <div className={classes.col}>
              <img src={Phone} loading="lazy" alt="Phone" />
            </div>
            <div className={classes.colQT}>
              <div className={classes.title}>
                <h1>{ourProducts[3].title}</h1>
              </div>
              <div className={classes.content}>
                <p>{ourProducts[3].content}</p>
              </div>
              <div className={classes.colQTSection}>
                <div className={classes.qtTitleHeader}>
                  <Galleries width="24" height="24" fill="#108B54" />
                  <div>
                    <Galleries width="24" height="24" fill="#108B54" />
                    <div className={classes.qtTitle}>
                      {ourProducts[4].title}
                    </div>
                    <p className={classes.qtContent}>
                      {ourProducts[4].content}
                    </p>
                  </div>
                </div>
                <div className={classes.qtTitleHeader}>
                  <Invitation width="36" height="36" fill="#108B54" />
                  <div>
                    <Invitation width="36" height="36" fill="#108B54" />
                    <div className={classes.qtTitle}>
                      {ourProducts[5].title}
                    </div>
                    <p className={classes.qtContent}>
                      {ourProducts[5].content}
                    </p>
                  </div>
                </div>
                <div className={classes.qtTitleHeader}>
                  <Messaging width="36" height="36" fill="#108B54" />
                  <div>
                    <Messaging width="36" height="36" fill="#108B54" />
                    <div className={classes.qtTitle}>
                      {ourProducts[6].title}
                    </div>
                    <p className={classes.qtContent}>
                      {ourProducts[6].content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.rowBox} data-cy="second-container">
            <div className={classes.col}>
              <div className={classes.box} ref={ref}>
                <div>
                  <h1>{ourProducts[7].title}</h1>
                </div>
                <p>{ourProducts[7].content}</p>
                <div className={classes.qAndButton}>
                  <img src={QIsometric} alt="Q Isometric" />
                </div>
                <Link
                  style={{ all: 'unset' }}
                  to="/get-your-q"
                  data-cy="btn-buy"
                >
                  <button type="button">BUY NOW</button>
                </Link>
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.box}>
                <div>
                  <h1>{ourProducts[8].title}</h1>
                </div>
                <p>{ourProducts[8].content}</p>
                <div className={classes.image} data-cy="qr-code">
                  <QRCode />
                </div>
                <div className={classes.storeBox} data-cy="twoButtons">
                  <a href={appStore} target="_BLANK" rel="noreferrer">
                    <AppStoreBadgeEn size={40} />
                  </a>
                  <a href={playStore} target="_BLANK" rel="noreferrer">
                    <PlayStoreBadgeEn size={40} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurProducts;
