import classes from './HeaderBanner.module.css';

interface HeaderBannerProps {
  title: string;
  background: string;
}

const HeaderBanner = ({ title, background }: HeaderBannerProps) => {
  return (
    <div
      className={classes.header}
      style={{ backgroundImage: `url(${background})` }}
      data-cy="headerBanner"
    >
      <div className={classes.headerContent}>
        <h1 className={classes.titleHeader}>{title}</h1>
      </div>
    </div>
  );
};

export default HeaderBanner;
