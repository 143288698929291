import { ThunkDispatch } from 'redux-thunk';
import { StripeService } from '../../services/stripe.service';
import { StripeMode } from '../../utils/constants';
import { ActionTypes } from '../constants/action.types';
import { RootState } from '../types';
import { IStripeAction } from './types';

const stripeService = new StripeService();

export const stripeRedirectAction = Object.assign(
  (price: string, mode: StripeMode) =>
    async (dispatch: ThunkDispatch<RootState, void, IStripeAction>) => {
      dispatch(stripeRedirectAction.start());
      try {
        await stripeService.redirectToStripe(price, mode);
        dispatch(stripeRedirectAction.success());
      } catch (error) {
        const errorMessage =
          (error as Error)?.message ??
          (error as string) ??
          'Something went wrong';
        dispatch(stripeRedirectAction.fail(errorMessage));
      }
    },
  {
    start: (): IStripeAction => ({ type: ActionTypes.GET_STRIPE_START }),
    success: (): IStripeAction => ({ type: ActionTypes.GET_STRIPE_SUCCESS }),
    fail: (error: string): IStripeAction => ({
      type: ActionTypes.GET_STRIPE_FAIL,
      error,
    }),
  }
);
