const SuccessImage = () => {
  return (
    <svg
      width="373"
      height="322"
      viewBox="0 0 373 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1434_68847)">
        <path
          d="M155.156 279.739L159.23 266.44C154.328 261.032 148.184 256.906 141.33 254.419C148.026 265.539 141.538 280.045 144.242 292.748C145.115 296.703 146.866 300.411 149.363 303.596C151.86 306.781 155.041 309.362 158.669 311.148L164.851 318.826C168.092 313.15 170.067 306.837 170.64 300.323C171.212 293.808 170.369 287.246 168.168 281.09C166.9 277.648 165.204 274.38 163.12 271.364C159.446 275.693 155.156 279.739 155.156 279.739Z"
          fill="#F2F2F2"
        />
        <path
          d="M132.756 281.438L130.485 267.714C123.692 265.065 116.356 264.118 109.115 264.956C120.056 271.916 120.703 287.8 128.774 297.961C131.316 301.111 134.533 303.647 138.185 305.381C141.837 307.116 145.832 308.004 149.874 307.98L158.823 312.089C159.199 305.56 158.158 299.027 155.772 292.94C153.386 286.853 149.712 281.357 145.003 276.83C142.336 274.317 139.364 272.15 136.157 270.382C134.795 275.899 132.756 281.438 132.756 281.438Z"
          fill="#F2F2F2"
        />
        <path
          d="M266.091 315.413L259.815 315.412L256.83 291.15L266.092 291.151L266.091 315.413Z"
          fill="#FFB6B6"
        />
        <path
          d="M267.691 321.51L247.457 321.509V321.253C247.457 319.159 248.287 317.151 249.764 315.671C251.241 314.19 253.244 313.359 255.333 313.359H255.333L259.029 310.548L265.925 313.359L267.691 313.359L267.691 321.51Z"
          fill="#2F2E41"
        />
        <path
          d="M367.46 292.469L363.181 297.071L343.439 282.717L349.754 275.927L367.46 292.469Z"
          fill="#FFB6B6"
        />
        <path
          d="M373.001 295.453L359.205 310.29L359.017 310.115C357.49 308.687 356.59 306.71 356.517 304.617C356.443 302.525 357.202 300.489 358.626 298.957L358.626 298.957L359.095 294.331L365.848 291.191L367.052 289.895L373.001 295.453Z"
          fill="#2F2E41"
        />
        <path
          d="M358.174 278.834L351.542 292.739L310.812 265.708L304.887 257.615L285.459 231.081L277.562 243.642L272.067 256.773C270.276 257.944 268.767 259.499 267.648 261.326C266.53 263.153 265.83 265.206 265.6 267.336L265.348 269.661L268.821 300.951L255.623 305.013L248.043 262.979L250.521 253.871L253.072 241.085L265.916 200.683H300.285L319.998 242.743L321.439 251.314L327.029 257.744L358.174 278.834Z"
          fill="#2F2E41"
        />
        <path
          d="M286.747 134.58L292.533 138.996L299.51 141.146C299.51 141.146 302.395 181.819 304.994 184.424C307.593 187.029 305.249 187.099 305.771 190.484C306.293 193.868 307.563 200.806 307.563 200.806C290.018 210.116 274.893 210.365 262.744 199.472C262.588 198.798 262.667 198.091 262.968 197.468C263.268 196.846 263.771 196.345 264.395 196.048C266.656 194.845 262.31 189.232 264.158 188.294C266.006 187.355 263.407 179.213 263.407 179.213L265.112 162.226L263.765 159.51L267.052 142.895L272.757 140.807L275.035 135.304L286.747 134.58Z"
          fill="#3F3D56"
        />
        <path
          d="M290.372 206.87C290.346 206.135 290.481 205.404 290.768 204.727C291.054 204.051 291.485 203.445 292.031 202.954C292.576 202.462 293.222 202.097 293.923 201.882C294.625 201.668 295.364 201.611 296.09 201.714L304.244 185.828L308.787 193.918L300.348 207.764C300.093 208.975 299.405 210.05 298.413 210.787C297.42 211.523 296.193 211.868 294.964 211.758C293.734 211.648 292.587 211.089 291.741 210.188C290.895 209.287 290.408 208.106 290.372 206.87H290.372Z"
          fill="#FFB6B6"
        />
        <path
          d="M304.103 202.983L321.592 175.813L321.534 175.688C321.468 175.544 317.508 166.971 313.67 159.117C313.079 157.908 312.491 156.716 311.921 155.574C311.675 155.081 311.432 154.599 311.193 154.127C309.582 150.942 308.173 148.309 307.333 147.059C305.474 144.231 302.706 142.127 299.488 141.094L299.407 141.073L299.33 141.105C297.756 141.771 296.456 142.954 295.643 144.46C294.83 145.966 294.554 147.704 294.859 149.389C296.376 157.514 299.757 165.175 304.733 171.766L307.568 175.512L297.716 196.985L304.103 202.983Z"
          fill="#3F3D56"
        />
        <path
          d="M243.964 98.8338C244.446 99.388 244.8 100.041 245.003 100.748C245.205 101.454 245.251 102.197 245.136 102.922C245.021 103.648 244.749 104.34 244.339 104.949C243.928 105.558 243.39 106.07 242.761 106.448L246.407 123.936L237.795 120.518L235.653 104.435C235.09 103.334 234.949 102.064 235.258 100.866C235.566 99.6677 236.302 98.6246 237.327 97.9341C238.351 97.2437 239.593 96.9539 240.816 97.1197C242.039 97.2855 243.16 97.8954 243.964 98.8338H243.964Z"
          fill="#FFB6B6"
        />
        <path
          d="M235.738 110.52L239.221 142.667L239.344 142.727C239.446 142.778 243.858 144.947 249.273 147.53C250.187 147.966 250.056 149.854 251.011 150.305C251.669 150.616 253.405 149.488 254.07 149.799C259.99 152.574 265.975 155.272 268.361 156.004C271.581 157.026 275.054 156.914 278.202 155.686L278.278 155.651L278.318 155.577C279.122 154.067 279.389 152.327 279.074 150.644C278.76 148.961 277.882 147.436 276.587 146.32C270.304 140.966 262.866 137.148 254.859 135.169L250.303 134.048L244.468 111.149L235.738 110.52Z"
          fill="#3F3D56"
        />
        <path
          d="M280.579 132.759C286.9 132.759 292.023 127.623 292.023 121.287C292.023 114.952 286.9 109.816 280.579 109.816C274.259 109.816 269.135 114.952 269.135 121.287C269.135 127.623 274.259 132.759 280.579 132.759Z"
          fill="#FFB6B6"
        />
        <path
          d="M269.216 113.6C270.157 110.856 274.842 108.952 282.437 108.687C290.033 108.423 291.959 117.395 291.959 117.395C297.029 121.518 286.545 132.556 286.037 132.158L286.266 130.085C286.643 126.649 288.248 123.969 286.207 120.389L284.247 120.412C281.716 120.9 279.185 121.387 276.655 121.875L279.651 119.231L279.566 119.1C278.292 119.765 276.905 120.187 275.476 120.345C273.866 120.439 272.105 119.797 271.334 118.377C271.128 117.986 271.002 117.559 270.964 117.119C268.792 117.988 267.799 120.672 267.799 120.672C267.799 120.672 268.275 116.345 269.216 113.6Z"
          fill="#2F2E41"
        />
        <path
          d="M46.1101 114.288C45.9548 114.091 45.7876 113.903 45.6094 113.727C45.6529 113.667 45.6965 113.601 45.74 113.541L46.1101 114.288Z"
          fill="#2F2E41"
        />
        <path
          d="M48.3358 160.427C46.4637 163.76 42.725 165.62 39.019 166.531C34.8563 167.624 30.4729 167.539 26.3554 166.286C25.833 166.117 25.3106 165.92 24.8045 165.702C25.0478 165.054 25.2407 164.389 25.3813 163.711C25.5708 162.794 25.6656 161.86 25.6643 160.923C25.252 161.73 24.7178 162.468 24.0807 163.111C23.5892 163.628 23.031 164.078 22.4209 164.447C20.5284 163.296 18.8897 161.77 17.6047 159.963C17.6863 159.762 17.768 159.56 17.8442 159.358C18.2393 158.304 18.5523 157.22 18.7802 156.118C19.0894 154.626 19.2444 153.107 19.2428 151.584C18.5717 152.897 17.7015 154.097 16.6632 155.141C16.3519 155.475 16.0212 155.79 15.6728 156.085C15.1332 154.442 14.9247 152.709 15.0592 150.985C15.1937 149.261 15.6686 147.581 16.4564 146.042C18.6115 142.049 22.7909 139.676 26.5133 137.096C30.2411 134.516 33.9689 131.024 34.2845 126.491C34.4967 123.431 33.0818 120.365 33.6478 117.354C33.8858 116.084 34.4669 114.904 35.3278 113.943C36.1887 112.981 37.2963 112.275 38.53 111.901C39.7637 111.527 41.0762 111.499 42.3245 111.821C43.5728 112.143 44.709 112.802 45.6093 113.727C43.4978 116.727 41.3972 119.831 40.5428 123.398C39.6721 127.031 40.385 131.292 43.2421 133.697C44.2216 134.527 45.4243 135.116 46.1808 136.152C47.1875 137.527 47.193 139.36 47.3562 141.051C47.6827 144.34 48.7548 147.504 49.3317 150.761C49.914 154.012 49.9466 157.541 48.3358 160.427Z"
          fill="#2F2E41"
        />
        <path
          d="M9.95898 295.703L14.9258 299.55L32.0912 282.177L24.7606 276.5L9.95898 295.703Z"
          fill="#A0616A"
        />
        <path
          d="M10.17 291.944L18.3833 297.556L15.7841 300.928L19.2651 311.877C19.4371 312.417 19.4321 312.999 19.251 313.537C19.0698 314.074 18.7219 314.54 18.258 314.866C17.7942 315.191 17.2387 315.359 16.6726 315.346C16.1066 315.333 15.5596 315.138 15.1116 314.791L5.76443 307.551L6.7243 302.968L3.52424 305.816L0 303.087L10.17 291.944Z"
          fill="#2F2E41"
        />
        <path
          d="M35.1563 123.98C35.1555 125.925 35.6312 127.841 36.5415 129.559C37.4517 131.277 38.7688 132.744 40.377 133.833C41.9853 134.921 43.8355 135.598 45.7653 135.802C47.6951 136.007 49.6455 135.733 51.4452 135.006C53.2449 134.278 54.839 133.119 56.0875 131.63C57.3359 130.141 58.2006 128.367 58.6056 126.465C59.0106 124.562 58.9435 122.589 58.4103 120.719C57.877 118.849 56.8939 117.138 55.5472 115.738L55.4903 115.681C55.3954 115.581 55.3006 115.481 55.201 115.386C55.2004 115.386 55.1998 115.386 55.1992 115.386C55.1986 115.385 55.1981 115.385 55.1976 115.384C55.1972 115.384 55.1968 115.384 55.1966 115.383C55.1964 115.382 55.1963 115.382 55.1963 115.381C55.073 115.267 54.9497 115.153 54.8216 115.044C52.7351 113.209 49.8025 111.217 46.8103 111.217C40.2615 111.217 35.1563 117.416 35.1563 123.98Z"
          fill="#2F2E41"
        />
        <path
          d="M102.73 117.877C102.787 118.511 102.704 119.15 102.488 119.748C102.272 120.346 101.928 120.89 101.479 121.341C101.031 121.791 100.49 122.138 99.8932 122.356C99.2968 122.574 98.66 122.658 98.0275 122.603L68.671 149.172L63.6191 141.174L94.0641 117.572C94.2273 116.514 94.7722 115.552 95.5957 114.869C96.4192 114.186 97.464 113.829 98.5322 113.867C99.6004 113.904 100.618 114.333 101.391 115.072C102.165 115.812 102.641 116.81 102.73 117.877V117.877Z"
          fill="#A0616A"
        />
        <path
          d="M56.124 157.192C56.124 157.192 57.2162 159.214 60.9473 156.091C63.9343 153.592 78.2447 142.74 81.3277 138.293C82.0317 138.572 82.8084 138.609 83.5356 138.397C84.2628 138.186 84.899 137.738 85.3441 137.123L76.8971 129.708C74.3697 130 74.4238 131.702 74.687 132.668L68.3153 135.562L51.8613 146.102L55.3085 155.744L56.124 157.192Z"
          fill="#1DAD6C"
        />
        <path
          d="M40.1846 175.822L39.5693 178.759L39.0469 189.593L62.049 190.861L61.3347 174.197L59.1051 170.525L40.1846 175.822Z"
          fill="#A0616A"
        />
        <path
          d="M49.9333 136.694C55.4236 136.694 59.8744 132.233 59.8744 126.73C59.8744 121.226 55.4236 116.765 49.9333 116.765C44.443 116.765 39.9922 121.226 39.9922 126.73C39.9922 132.233 44.443 136.694 49.9333 136.694Z"
          fill="#A0616A"
        />
        <path
          d="M32.2838 163.484L32.2227 155.201C32.2213 152.221 33.2274 149.328 35.0771 146.994C36.9267 144.661 39.5106 143.024 42.4073 142.352L42.4448 142.343L44.4105 139.688L52.3969 140.489L53.8607 143.488L59.6478 146.942L61.8759 154.122C62.8423 154.767 63.6659 155.605 64.2956 156.584C64.9253 157.562 65.3476 158.66 65.5362 159.808C66.2926 164.652 62.9095 169.498 62.5071 170.054L61.4702 175.907L38.9139 179.897L38.7307 179.92L32.2838 163.484Z"
          fill="#1DAD6C"
        />
        <path
          d="M62.0976 124.953C60.938 125.689 59.7349 126.354 58.4949 126.944C57.2364 127.55 55.92 128.027 54.5658 128.368C54.1783 127.516 53.7042 126.706 53.1509 125.951C53.2932 126.845 53.2969 127.756 53.1617 128.652C50.9171 129.015 48.6182 128.826 46.4626 128.101L44.5906 124.953L44.4491 128.466C42.8981 128.75 41.3525 129.017 39.8016 129.295C39.7091 129.306 39.6166 129.328 39.5295 129.344C38.5662 124.991 39.9322 120.196 42.9688 117.294C44.4765 115.868 46.3573 114.901 48.3921 114.505C50.4269 114.109 52.532 114.301 54.4624 115.058C54.7073 115.156 54.9576 115.265 55.197 115.38C55.197 115.38 55.1972 115.381 55.1974 115.382C55.1977 115.382 55.1981 115.383 55.1986 115.383C55.1991 115.384 55.1997 115.384 55.2004 115.385C55.2011 115.385 55.2018 115.385 55.2025 115.385C55.344 115.456 55.4637 115.625 55.6215 115.609C62.4132 114.85 62.6309 120.131 62.0976 124.953Z"
          fill="#2F2E41"
        />
        <path
          d="M45.5605 282.854L50.5351 286.69L67.6657 269.283L60.3239 263.621L45.5605 282.854Z"
          fill="#A0616A"
        />
        <path
          d="M46.2139 279.443L54.4385 285.037L51.8461 288.414L55.3492 299.356C55.5223 299.897 55.5185 300.478 55.3384 301.016C55.1583 301.555 54.8113 302.021 54.3481 302.347C53.8849 302.674 53.3298 302.843 52.7637 302.831C52.1976 302.819 51.6502 302.625 51.2015 302.279L41.8398 295.058L42.7905 290.473L39.5961 293.328L36.0664 290.605L46.2139 279.443Z"
          fill="#2F2E41"
        />
        <path
          d="M29.4182 261.609L29.4179 261.544L31.7756 257.471L29.3682 252.17L29.2959 238.55L29.6862 236.506C30.2111 233.756 30.0583 230.919 29.2413 228.242L29.1973 219.952C29.1127 217.875 32.3223 216.049 31.5738 214.317C24.4992 197.952 38.7982 185.468 38.8947 185.368L39.1869 185.063L40.7723 186.267L42.2813 186.269L62.6446 189.988L62.7916 190.263C63.5925 191.769 65.1383 196.959 65.2299 199.308C66.2448 200.486 77.805 213.895 78.8326 214.826C85.7769 221.124 89.7528 231.549 91.5047 237.202C91.9398 238.6 92.0275 240.082 91.7602 241.521C91.4929 242.96 90.8786 244.312 89.9709 245.458L63.6817 274.322L56.6071 267.776L70.832 241.793L69.8062 239.157C67.9657 238.817 66.3001 237.847 65.0935 236.413L62.2131 232.986C61.4156 232.039 60.9088 230.882 60.7541 229.653L59.0073 229.442L52.7502 222.857L48.4092 262.263L47.229 265.376L26.676 289.596L17.9688 284.141L29.4182 261.609Z"
          fill="#2F2E41"
        />
        <path
          d="M26.279 92.4024C25.8841 92.9007 25.603 93.4797 25.4556 94.0987C25.3081 94.7177 25.2978 95.3615 25.4254 95.9849C25.553 96.6083 25.8154 97.196 26.1942 97.7067C26.5729 98.2175 27.0587 98.6387 27.6175 98.9409L21.082 118.335L26.4186 138.888L35.0238 134.988L29.7137 119.486L33.6865 96.921C34.1306 95.9465 34.2027 94.8425 33.8891 93.8183C33.5755 92.794 32.898 91.9207 31.9849 91.3637C31.0719 90.8067 29.9867 90.6048 28.9351 90.7963C27.8834 90.9877 26.9384 91.5592 26.279 92.4024Z"
          fill="#A0616A"
        />
        <path
          d="M34.1549 155.822C34.1549 155.822 32.1338 156.91 30.7281 152.246C29.6028 148.512 21.9447 128.24 21.8067 122.826C21.0652 122.672 20.3961 122.275 19.9045 121.698C19.413 121.12 19.1271 120.395 19.0918 119.637L30.2144 118.094C32.1661 119.73 31.1879 121.122 30.4383 121.785L34.2266 128.217L42.1447 145.581L35.6297 155.062L34.1549 155.822Z"
          fill="#1DAD6C"
        />
        <path
          d="M39.7988 129.294C40.941 125.842 42.9892 122.76 45.7284 120.374C48.4676 117.988 51.7969 116.385 55.3671 115.733C55.3813 115.728 55.4287 115.709 55.4904 115.681C55.5283 115.657 55.5757 115.633 55.6232 115.609C55.4809 115.529 55.3434 115.457 55.2011 115.386C55.2005 115.386 55.1999 115.386 55.1993 115.386C55.1987 115.385 55.1982 115.385 55.1977 115.384C55.1973 115.384 55.1969 115.384 55.1967 115.383C55.1965 115.382 55.1964 115.382 55.1964 115.381C55.0731 115.267 54.9498 115.153 54.8217 115.044L54.4613 115.058C54.4613 115.058 41.7953 113.047 39.7988 129.294Z"
          fill="#FD6584"
        />
        <path
          d="M203.238 240.337C204.621 238.254 203.802 235.273 201.411 233.678C199.019 232.083 195.96 232.478 194.577 234.561C193.195 236.644 194.013 239.625 196.405 241.22C198.796 242.815 201.856 242.42 203.238 240.337Z"
          fill="#F2F2F2"
        />
        <path
          d="M175.176 162.601C176.558 160.518 175.74 157.536 173.348 155.941C170.957 154.346 167.897 154.742 166.515 156.825C165.132 158.907 165.951 161.889 168.342 163.484C170.734 165.079 173.793 164.683 175.176 162.601Z"
          fill="#F2F2F2"
        />
        <path
          d="M292.523 282.274C293.906 280.191 293.088 277.21 290.696 275.615C288.304 274.02 285.245 274.415 283.862 276.498C282.48 278.581 283.298 281.562 285.69 283.157C288.082 284.752 291.141 284.357 292.523 282.274Z"
          fill="#F2F2F2"
        />
        <path
          d="M55.2773 32.1873C56.6597 30.1046 55.8415 27.1232 53.4498 25.5282C51.0581 23.9332 47.9986 24.3286 46.6163 26.4113C45.2339 28.4941 46.0521 31.4755 48.4438 33.0705C50.8355 34.6655 53.895 34.2701 55.2773 32.1873Z"
          fill="#F2F2F2"
        />
        <path
          d="M165.992 7.63897C167.375 5.55621 166.556 2.57481 164.165 0.979808C161.773 -0.615192 158.714 -0.219792 157.331 1.86297C155.949 3.94572 156.767 6.92716 159.159 8.52216C161.55 10.1172 164.61 9.72173 165.992 7.63897Z"
          fill="#F2F2F2"
        />
        <path
          d="M113.594 213.597C114.976 211.514 114.158 208.533 111.766 206.938C109.375 205.343 106.315 205.738 104.933 207.821C103.55 209.904 104.369 212.885 106.76 214.48C109.152 216.075 112.211 215.68 113.594 213.597Z"
          fill="#E6E6E6"
        />
        <path
          d="M341.974 322H10.1897C10.0289 322 9.87484 321.935 9.76129 321.821C9.64774 321.707 9.58398 321.552 9.58398 321.391C9.58398 321.23 9.64774 321.075 9.76129 320.961C9.87484 320.847 10.0289 320.783 10.1897 320.782H341.974C342.135 320.782 342.289 320.846 342.403 320.961C342.517 321.075 342.581 321.23 342.581 321.391C342.581 321.553 342.517 321.708 342.403 321.822C342.289 321.936 342.135 322 341.974 322Z"
          fill="#CCCCCC"
        />
        <path
          d="M67.8668 51.8583C70.121 51.8583 71.9485 50.0265 71.9485 47.7669C71.9485 45.5073 70.121 43.6755 67.8668 43.6755C65.6126 43.6755 63.7852 45.5073 63.7852 47.7669C63.7852 50.0265 65.6126 51.8583 67.8668 51.8583Z"
          fill="#3F3D56"
        />
        <path
          d="M250.521 31.4013C252.775 31.4013 254.603 29.5695 254.603 27.3099C254.603 25.0503 252.775 23.2185 250.521 23.2185C248.267 23.2185 246.439 25.0503 246.439 27.3099C246.439 29.5695 248.267 31.4013 250.521 31.4013Z"
          fill="#3F3D56"
        />
        <path
          d="M195.338 115.396C194.963 115.066 194.666 114.656 194.468 114.196C194.271 113.737 194.178 113.239 194.197 112.739C194.402 106.796 193.426 100.872 191.325 95.3112C191.147 94.8437 191.075 94.3423 191.114 93.8435C191.153 93.3447 191.303 92.8609 191.552 92.4273C191.791 92.0107 192.118 91.6515 192.51 91.3749C192.902 91.0984 193.35 90.9113 193.822 90.8267C198.566 87.9569 203.43 87.1513 208.28 88.4326C209.063 88.3172 209.86 88.485 210.53 88.906C211.2 89.3269 211.698 89.9734 211.935 90.7294C213.899 97.1201 214.991 103.748 215.182 110.432C215.201 111.234 214.93 112.016 214.419 112.633C213.909 113.251 213.193 113.664 212.404 113.795L198.05 116.172C197.569 116.253 197.076 116.225 196.607 116.091C196.138 115.956 195.704 115.719 195.338 115.396Z"
          fill="#1DAD6C"
        />
        <path
          d="M196.613 98.0814L208.694 96.0807C208.793 96.0645 208.889 96.0288 208.974 95.9757C209.06 95.9225 209.134 95.853 209.193 95.771C209.251 95.6891 209.293 95.5963 209.316 95.4981C209.339 95.3998 209.343 95.298 209.326 95.1985C209.31 95.0989 209.274 95.0036 209.221 94.918C209.168 94.8323 209.098 94.7581 209.016 94.6994C208.934 94.6407 208.842 94.5989 208.744 94.5761C208.645 94.5534 208.544 94.5503 208.445 94.5669L196.363 96.5676C196.164 96.6012 195.985 96.7129 195.867 96.8782C195.749 97.0435 195.702 97.2488 195.735 97.4493C195.768 97.6497 195.879 97.8288 196.043 97.9473C196.208 98.0658 196.413 98.114 196.613 98.0814Z"
          fill="white"
        />
        <path
          d="M197.611 104.136L209.692 102.136C209.892 102.103 210.071 101.991 210.189 101.826C210.307 101.66 210.355 101.455 210.322 101.254C210.289 101.053 210.178 100.874 210.013 100.755C209.848 100.637 209.642 100.589 209.442 100.622L197.361 102.623C197.262 102.639 197.167 102.675 197.081 102.728C196.995 102.781 196.921 102.85 196.862 102.932C196.804 103.014 196.762 103.107 196.739 103.205C196.716 103.304 196.713 103.405 196.729 103.505C196.745 103.604 196.781 103.7 196.834 103.785C196.888 103.871 196.957 103.945 197.039 104.004C197.121 104.063 197.214 104.105 197.312 104.127C197.41 104.15 197.511 104.153 197.611 104.136Z"
          fill="white"
        />
        <path
          d="M198.609 110.191L210.69 108.191C210.89 108.158 211.069 108.046 211.187 107.881C211.306 107.715 211.353 107.51 211.32 107.309C211.287 107.108 211.176 106.929 211.011 106.81C210.846 106.692 210.641 106.644 210.44 106.677L198.359 108.678C198.26 108.694 198.165 108.73 198.08 108.783C197.995 108.836 197.92 108.906 197.862 108.988C197.803 109.07 197.762 109.162 197.739 109.26C197.716 109.359 197.713 109.46 197.729 109.56C197.745 109.659 197.781 109.754 197.834 109.84C197.887 109.925 197.957 110 198.038 110.058C198.12 110.117 198.212 110.159 198.31 110.182C198.408 110.205 198.51 110.208 198.609 110.191Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1434_68847">
          <rect width="373" height="322" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessImage;
