import { AxiosResponse } from 'axios';
import { freshdeskAxios } from '../config/freshdesk-axios.config';
import { IEmailParts } from '../utils/constants';
import {
  FreshdeskEmailType,
  IFreshdeskEmail,
} from '../utils/constants/freshdesk';

export class FreshdeskService {
  private support(mail: IFreshdeskEmail): IEmailParts {
    const { name, email, message } = mail;
    return {
      subject: 'Support',
      body: `
            <div>
              <p>Hello there! ${name} is requesting support!</p>
              <br />
              <p><strong>Email:</strong> ${email}</p>
              <p><strong>Message:</strong> ${message}</p>
            </div>
          `,
    };
  }

  private orderHq(mail: IFreshdeskEmail): IEmailParts {
    const { name, email } = mail;
    return {
      subject: 'Order hQ',
      body: `
            <div>
              <p>There is a new client who wants a new hQ!</p>
              <br />
              <p><strong>Name:</strong> ${name}</p>
              <p><strong>Email:</strong> ${email}</p>
            </div>
        `,
    };
  }

  private orderVq(mail: IFreshdeskEmail): IEmailParts {
    const { name, email } = mail;
    return {
      subject: 'Order vQ',
      body: `
            <div>
              <p>There is a new client who wants a new vQ!</p>
              <br />
              <p><strong>Name:</strong> ${name}</p>
              <p><strong>Email:</strong> ${email}</p>
            </div>
        `,
    };
  }

  async sendEmail(
    mail: IFreshdeskEmail,
    type: FreshdeskEmailType
  ): Promise<any> {
    const url = `${process.env.REACT_APP_FRESHDESK_URL}`;

    const { email } = mail;

    const getEmail = {
      support: this.support,
      orderHq: this.orderHq,
      orderVq: this.orderVq,
    };

    const emailParts = getEmail[type](mail);

    const data = {
      description: emailParts.body,
      subject: emailParts.subject,
      email,
      priority: 1,
      status: 2,
      product_id: 69000001102,
    };

    const req: AxiosResponse = await freshdeskAxios.post(url, data);

    return req.data;
  }
}
